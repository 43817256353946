import axios from "axios";

let API_URL = "/legal-entities";

const legalStockCreate = async (data) =>{
    const response = await axios.post(API_URL,data)
    return response.status
}
const legalStockService ={
    legalStockCreate
}
export default legalStockService