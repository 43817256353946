import { useEffect } from "react";
import { useSelector } from "react-redux";
import LegalCreateForm from "./create-user-form-legal/create-legal-form";
import UserCreateForm from "./create-user-form/create-user-form";

function CreateUser({ titleChange }) {
  // Определяет выбор юридического или физического лица
  const toggleValue = useSelector((state) => state.toggle);
  //

  // Title страницы передаем в шапку
  useEffect(() => {
    toggleValue
      ? titleChange("Новое юридическое лицо")
      : titleChange("Новый клиент");
  }, [titleChange, toggleValue]);
  //

  return (
    <section className="create-new-user-section section-border">
      <div className="row gap-1">
        <div className="col-md-12 col-lg-12 col-xl-12">
          {toggleValue ? <LegalCreateForm /> : <UserCreateForm />}
        </div>
      </div>
    </section>
  );
}

export default CreateUser;
