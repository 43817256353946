import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import roleService from "./role-service";

const initialState = {
  role: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// country Alls
export const roleData = createAsyncThunk("role", async (thunkAPI) => {
  try {
    return await roleService.role();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data) ||
      error.message ||
      error.toSrting();
    return thunkAPI.rejectWithValue(message);
  }
});
//

const roleSlice = createSlice({
  name: "roleSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(roleData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(roleData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.role = action.payload;
      })
      .addCase(roleData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default roleSlice.reducer;
