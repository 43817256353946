import { Outlet, Navigate, Link, useMatch } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { Col } from "react-bootstrap";
import AppHeader from "../app-header/app-header";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { roleData } from "../../store/role/role-slice";
import { useSelector } from "react-redux";

const PrivateRoutes = ({ title }) => {
  const dispatch = useDispatch();
  let auth = { token: localStorage.getItem("bitok_auth_token") };

  // Проверка route Для подсветки в меню
  const isHomeRoute = useMatch("/");
  const isCreateUser = useMatch("/create-user");
  const isUserList = useMatch("/user-list");
  const isHistoryOperation = useMatch("/history-operation");
  const isUpdateBlackList = useMatch("/update-black-list");
  const isCountryFilter = useMatch("/countries-filter");
  const isSetting = useMatch("/settings");
  const isSuspicious = useMatch("/suspicious");
  const isShipment = useMatch("/shipment");
  //

  useEffect(function () {
    dispatch(roleData());
  }, []);

  const { role } = useSelector((state) => state.role);
  return auth.token ? (
    <div className="adminx-container">
      <AppHeader title={title} />
      <div className="adminx-sidebar expand-hover push">
        <ul className="sidebar-nav">
          <Nav variant="pills" className="flex ">
            {/* Главная */}
            <li className="sidebar-nav-item">
              <Link
                to={"/"}
                className={`sidebar-nav-link ${isHomeRoute ? "active" : ""}`}
              >
                <span className="sidebar-nav-name">Добавить операцию</span>
                <span className="sidebar-nav-end"></span>
              </Link>
            </li>
            {/*  */}

            {/* Создание пользователя */}
            <li className="sidebar-nav-item">
              <Link
                to={"/create-user"}
                className={`sidebar-nav-link ${isCreateUser ? "active" : ""}`}
              >
                <span className="sidebar-nav-name">Новый клиент</span>
                <span className="sidebar-nav-end"></span>
              </Link>
            </li>
            {/*  */}

            {/* Список пользователей */}
            <li className="sidebar-nav-item">
              <Link
                to={"/user-list"}
                className={`sidebar-nav-link ${isUserList ? "active" : ""}`}
              >
                <span className="sidebar-nav-name">Клиенты</span>
                <span className="sidebar-nav-end"></span>
              </Link>
            </li>
            {/*  */}

            <li className="sidebar-nav-item">
              <Link
                to={"/history-operation"}
                className={`sidebar-nav-link ${
                  isHistoryOperation ? "active" : ""
                }`}
              >
                <span className="sidebar-nav-name">История операций</span>
                <span className="sidebar-nav-end"></span>
              </Link>
            </li>
            {role.role !== "Operator" && (
              <>
                <li className="sidebar-nav-item">
                  <Link
                    to={"update-black-list"}
                    className={`sidebar-nav-link ${
                      isUpdateBlackList ? "active" : ""
                    }`}
                  >
                    <span className="sidebar-nav-name">Обновить ЧС</span>
                    <span className="sidebar-nav-end"></span>
                  </Link>
                </li>
                <li className="sidebar-nav-item">
                  <Link
                    to={"countries-filter"}
                    className={`sidebar-nav-link ${
                      isCountryFilter ? "active" : ""
                    }`}
                  >
                    <span className="sidebar-nav-name">Страны</span>
                    <span className="sidebar-nav-end"></span>
                  </Link>
                </li>
                <li className="sidebar-nav-item">
                  <Link
                    to={"settings"}
                    className={`sidebar-nav-link ${isSetting ? "active" : ""}`}
                  >
                    <span className="sidebar-nav-name">Настройки</span>
                    <span className="sidebar-nav-end"></span>
                  </Link>
                </li>
                <li className="sidebar-nav-item">
                  <Link
                    to={"suspicious"}
                    className={`sidebar-nav-link ${
                      isSuspicious ? "active" : ""
                    }`}
                  >
                    <span className="sidebar-nav-name">Подозрительные</span>
                    <span className="sidebar-nav-end"></span>
                  </Link>
                </li>
                <li className="sidebar-nav-item">
                  <Link
                    to={"shipment"}
                    className={`sidebar-nav-link ${isShipment ? "active" : ""}`}
                  >
                    <span className="sidebar-nav-name">На отправку</span>
                    <span className="sidebar-nav-end"></span>
                  </Link>
                </li>
              </>
            )}
          </Nav>
        </ul>
      </div>
      <div className="adminx-content">
        <div className="adminx-main-content">
          <Col sm={12}>
            <Outlet />
          </Col>
        </div>
      </div>
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoutes;
