import stockModalStyle from "./info-stock-legal.module.css"
const InfoStockLegal = ({info, editOn, setEdit, editInfo})=>{
    return (
    <div>
        <ul  className={stockModalStyle.user_info_list_item}>
            <li >
                <b>НАИМЕНОВАНИЕ :</b>
                <span
                    className={editOn ? "form-control w-auto" : stockModalStyle.user__editinput }
                    contentEditable={editOn ? "true" : "false"}
                    onInput={(e) => {
                        setEdit({ ...editInfo, name: e.target.innerText });
                    }}
                >
                    {info.name}
                </span>
            </li>
            <li>
                <b>АДРЕС</b>
                <span
                    className={editOn ? "form-control w-auto" : stockModalStyle.user__editinput }
                    contentEditable={editOn ? "true" : "false"}
                    onInput={(e) => {
                        setEdit({ ...editInfo, address: e.target.innerText });
                    }}
                >
                    {info.address}
                </span>
            </li>
            <li>
                <b>IBAN</b>
                <span
                    className={editOn ? "form-control w-auto" : stockModalStyle.user__editinput }
                    contentEditable={editOn ? "true" : "false"}
                    onInput={(e) => {
                        setEdit({ ...editInfo, iban: e.target.innerText });
                    }}
                >
                    {info.iban}
                </span>
            </li>
            <li>
                <b>БАНКОВСКИЙ СЧЕТ</b>
                <span
                    className={editOn ? "form-control w-auto" : stockModalStyle.user__editinput }
                    contentEditable={editOn ? "true" : "false"}
                    onInput={(e) => {
                        setEdit({ ...editInfo, bank_account: e.target.innerText });
                    }}
                >
                    {info.bank_account}
                </span>
            </li>
            <li>
                <b>НАИМЕНОВАНИЯ БАНКА</b>
                <span
                      className={editOn ? "form-control w-auto" : stockModalStyle.user__editinput }
                    contentEditable={editOn ? "true" : "false"}
                    onInput={(e) => {
                        setEdit({ ...editInfo, bank_name: e.target.innerText });
                    }}
                >
                    {info.bank_name}
                </span>
            </li>
            <li>
                <b>SWIFT</b>
                <span
                      className={editOn ? "form-control w-auto" : stockModalStyle.user__editinput }
                    contentEditable={editOn ? "true" : "false"}
                    onInput={(e) => {
                        setEdit({ ...editInfo, swift: e.target.innerText });
                    }}
                >
                    {info.swift}
                </span>
            </li>
            <li>
                <b>КОД АККАУНТА</b>
                <span
                      className={editOn ? "form-control w-auto" : stockModalStyle.user__editinput }
                    contentEditable={editOn ? "true" : "false"}
                    onInput={(e) => {
                        setEdit({ ...editInfo, account_code: e.target.innerText });
                    }}
                >
                    {info.account_code}
                </span>
            </li>
        </ul>
    </div>
    )
}
export default InfoStockLegal