import axios from "axios";
import { useState } from "react";
import swal from "sweetalert";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import moment from "moment";
import { useSelector } from "react-redux";

function CreateOperationAdd({ chekId, liveAdd }) {
  // Определяет выбор юридического или физического лица
  const toggleValue = useSelector((state) => state.toggle);
  //
  const [operationAdd, setoperationAdd] = useState({
    date: "",
    direction: "",
    summa: "",
    wallet_id: "",
    currency: "",
  });
  const [wallet_photo, setUploadWalletPhoto] = useState("");
  const [validated, setValidated] = useState(false);

  const [key, setKey] = useState(Date.now());

  const handleInput = (e) => {
    e.persist();
    if (/^[A-Za-z0-9]+$/.test(e.target.value)) {
      setoperationAdd({ ...operationAdd, [e.target.name]: e.target.value });
    }
    setoperationAdd({ ...operationAdd, [e.target.name]: e.target.value });
  };

  const handleWalletPhotoUpload = (event) => {
    setUploadWalletPhoto(event.target.files[0]);
  };

  const submitOperation = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      setValidated(true);
    } else {
      e.preventDefault();
      setValidated(true);
      const formData = new FormData();
      formData.append(
        "operation_date",
        moment(operationAdd.date).format("DD/MM/YYYY HH:mm")
      );
      console.log(wallet_photo);
      formData.append("wallet_id", operationAdd.wallet_id);
      formData.append("currency", operationAdd.currency);
      formData.append("operation_direction", operationAdd.direction);
      formData.append("operation_sum", Number(operationAdd.summa));
      console.log(toggleValue)
      if (toggleValue) {
        formData.append("legal_id", Number(chekId));
      } else {
        formData.append("user_id", Number(chekId));
      }
      if (wallet_photo !== "") {
        formData.append("wallet_photo[]", wallet_photo);
      }

      axios
        .post("/user-operations", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status === 201) {
            liveAdd();
            swal("Успешно", "Вы создали операцию", "success");

            // Очищаем поля
            setoperationAdd({
              date: "",
              direction: "",
              currency: "",
              summa: "",
              wallet_id: "",
            });
            setKey(Date.now());
            //
            setValidated(false);
          } else {
            swal("Ошибка", "При создание операции произошла ошибка", "error");
          }
        });
    }
  };
  return (
    <>
      <Form
        noValidate
        onSubmit={submitOperation}
        validated={validated}
        id="OPERATIONADD"
      >
        <Row className="mb-3 align-items-start">
          {/* Дата */}
          <Form.Group
            as={Col}
            md="12"
            className="mb-3 "
            controlId="validationCustom03"
          >
            <Form.Label>Дата</Form.Label>
            <Form.Control
              type="datetime-local"
              value={operationAdd.date}
              onChange={handleInput}
              name="date"
              required
            />
            <Form.Control.Feedback type="invalid">
              Заполните поле Дата
            </Form.Control.Feedback>
            <Form.Control.Feedback>
              Поле успешно заполнено
            </Form.Control.Feedback>
          </Form.Group>
          {/*  */}
          <hr />
          {/* Номер кошелька */}
          <Form.Group
            as={Col}
            md="12"
            className="mb-1 mt-4"
            controlId="validationCustom03"
          >
            <Form.Label>Номер кошелька</Form.Label>
            <Form.Control
              type="text"
              value={operationAdd.wallet_id}
              onChange={handleInput}
              name="wallet_id"
              pattern="[A-Za-z0-9]*"
              required={
                operationAdd.wallet_id === "" && wallet_photo === ""
                  ? true
                  : false
              }
            />
            <Form.Control.Feedback type="invalid">
              Заполните поле Дата
            </Form.Control.Feedback>
            <Form.Control.Feedback>
              Поле успешно заполнено
            </Form.Control.Feedback>
          </Form.Group>
          {/*  */}
          <div className="d-flex justify-content-center w-100"> и/или</div>

          {/* Фото кошелка */}
          <Form.Group
            as={Col}
            md="12"
            className={` mb-5`}
            controlId="validationCustom06"
          >
            <Form.Label>QR кошелька</Form.Label>
            <Form.Control
              key={key}
              type={"file"}
              accept=".png, .jpg, .jpeg"
              onChange={handleWalletPhotoUpload}
              name="wallet_photo"
              required={
                operationAdd.wallet_id === "" && wallet_photo === ""
                  ? true
                  : false
              }
            />
            <Form.Control.Feedback type="invalid">
              Заполните поле Дата первичной верификации
            </Form.Control.Feedback>
            <Form.Control.Feedback>
              Поле успешно заполнено
            </Form.Control.Feedback>
          </Form.Group>
          {/*  */}
          <hr />

          {/* Сумма */}
          <Form.Group
            as={Col}
            md="4"
            className="mb-3 "
            controlId="validationCustom04"
          >
            <Form.Label>Сумма</Form.Label>
            <Form.Control
              type="number"
              name="summa"
              className="form-control"
              value={operationAdd.summa}
              onChange={handleInput}
              required
            />
            <Form.Control.Feedback type="invalid">
              Заполните поле Сумма
            </Form.Control.Feedback>
            <Form.Control.Feedback>
              Поле успешно заполнено
            </Form.Control.Feedback>
          </Form.Group>
          {/*  */}

          {/* Валюта */}
          <Form.Group
            as={Col}
            md="4"
            className="mb-3 "
            controlId="validationCustom03"
          >
            <Form.Label>Валюта</Form.Label>

            <Form.Select
              onChange={handleInput}
              name="currency"
              defaultValue={operationAdd.currency}
              required
            >
              <option
                selected={operationAdd.currency === ""}
                value=""
                disabled={true}
              >
                Не выбрано
              </option>
              <option value="som">Сом</option>
              <option value="usd">USD</option>
              <option value="usdt">USDT</option>
              <option value="rub">Рубль</option>
            </Form.Select>

            <Form.Control.Feedback type="invalid">
              Заполните поле Дата
            </Form.Control.Feedback>
            <Form.Control.Feedback>
              Поле успешно заполнено
            </Form.Control.Feedback>
          </Form.Group>
          {/*  */}

          {/* Направление */}
          <Form.Group
            as={Col}
            md="4"
            className="mb-3 "
            controlId="validationCustom03"
          >
            <Form.Label> Направление</Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="direction"
              defaultValue={operationAdd.direction}
              onChange={handleInput}
              required
            >
              <option
                selected={operationAdd.direction === ""}
                value=""
                disabled={true}
              >
                Не выбрано
              </option>
              <option value="Продажа">Продажа</option>
              <option value="Пополнение">Пополнение</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Заполните поле Направление
            </Form.Control.Feedback>
            <Form.Control.Feedback>
              Поле успешно заполнено
            </Form.Control.Feedback>
          </Form.Group>
          {/*  */}
        </Row>

        <Button type="submit">Создать</Button>
      </Form>
      <hr />
    </>
  );
}

export default CreateOperationAdd;
