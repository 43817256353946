import axios from "axios";

// Users All LiST
let API_URL = "/legal-entities";

// Список USer/
const legalCreate = async (data) => {
  const response = await axios.post(API_URL, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.status;
};
//
const legalService = {
  legalCreate,
};

export default legalService;
