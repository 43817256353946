import { createAsyncThunk } from "@reduxjs/toolkit";
import legalService from "../legal-create-service";

// legal create
export const legalCreate = createAsyncThunk(
  "legal/create",
  async (data, thunkAPI) => {
    try {
      return await legalService.legalCreate(data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//
