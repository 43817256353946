import { configureStore } from "@reduxjs/toolkit";
// import authReducer from "./auth/authSlice";
import countrySlice from "./country/country-slice";
import toggleSlice from "./legal-individual/legal-individual-slice";
import roleSlice from "./role/role-slice";

const store = configureStore({
  reducer: {
    // auth: authReducer,
    country: countrySlice,
    toggle: toggleSlice,
    role: roleSlice,
  },
});

export default store;
