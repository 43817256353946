import { useEffect, useState } from "react";
import CreateOperationAdd from "./add/add";
import CreateOperationForm from "./form/form";
import OperationList from "./list-operation/list-operation";
import CreateOperationList from "./list/list";
import operationListStyle from "./listOperation.module.css";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import LegalCreateOperationList from "./list/list-legal";

function CreateOperation({ titleChange }) {
  // Определяет выбор юридического или физического лица
  const toggleValue = useSelector((state) => state.toggle);
  //

  // Title страницы передаем в шапку
  useEffect(() => {
    if (toggleValue) {
      titleChange("Добавить операцию для юридических лиц");
    } else {
      titleChange("Добавить операцию");
    }
  }, [titleChange, toggleValue]);

  //
  const [operation, setOperation] = useState([]);
  const [operationChek, setoperationChek] = useState("");
  const [operationUser, setoperationBl] = useState("");
  const [operationUserBlackListType, setoperationBlType] = useState("");
  const [operationLive, setOperationLive] = useState(false);
  const [createOperationAdd, setCreateOperationAdd] = useState(false);
  const [user, setUser] = useState("");
  const [userFindValue, setUserFindValue] = useState("");
  const operationFun = (value) => {
    setOperation(value);
  };

  const operationCheckbox = (value) => {
    setoperationChek(value);
  };

  const operationUserBl = (value) => {
    setoperationBl(value);
  };

  const operationUserBlType = (value) => {
    setoperationBlType(value);
  };

  const operLive = () => {
    setOperationLive(!operationLive);
  };

  const liveOperation = () => {
    setCreateOperationAdd(!createOperationAdd);
  };
  const [show, setShow] = useState(false);

  function handleShow(show) {
    setShow(show);
  }
  return (
    <section className="create-new-operation">
      <div className="row">
        <div className="col-md-12 col-lg-12 col-xl-12 section-border">
          {/* Форма для поиска пользователей */}
          <CreateOperationForm
            operation={operationFun}
            userCheck={operationCheckbox}
            setUserFindValue={setUserFindValue}
          />
          {/*  */}
          <hr />

          {toggleValue ? (
            <LegalCreateOperationList
              list={operation}
              check={operationCheckbox}
              user_bl={operationUserBl}
              type_bl={operationUserBlType}
              live={operLive}
              modalShow={handleShow}
              user={setUser}
              userFindValue={userFindValue}
            />
          ) : (
            <CreateOperationList
              list={operation}
              check={operationCheckbox}
              user_bl={operationUserBl}
              type_bl={operationUserBlType}
              live={operLive}
              modalShow={handleShow}
              user={setUser}
              userFindValue={userFindValue}
            />
          )}
          {/* Пользователи  */}

          {/*  */}
        </div>

        {toggleValue ? (
          <Modal size="lg" show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
              <Modal.Title>{`${user.name}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* Создание опрерации */}
              {operation.length !== 0 && (
                <div className="col-md-12 col-lg-12 col-xl-12 section-border additional_tab">
                  {operationUser && operationChek !== "" ? (
                    <div className={operationListStyle.black_list}>
                      <h3> Клиент находится в списке - </h3>
                      <h3>
                        {operationUserBlackListType === "forall"
                          ? "Сводный санкционный перечень"
                          : operationUserBlackListType === "ptf"
                          ? "Закрытый перечень физических лиц"
                          : "Перечень лиц преступных доходов"}
                      </h3>
                    </div>
                  ) : (
                    <>
                      {operationChek !== "" && (
                        <>
                          <CreateOperationAdd
                            chekId={operationChek}
                            isInBlckList={operationUser}
                            liveAdd={liveOperation}
                          />
                          <OperationList
                            chekId={operationChek}
                            isInBlckList={operationUser}
                            type={operationUserBlackListType}
                            radioList={operationLive}
                            renderList={createOperationAdd}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
              {/*  */}
            </Modal.Body>
          </Modal>
        ) : (
          <Modal size="lg" show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
              <Modal.Title>{`${user.last_name} ${user.first_name} ${user.middle_name}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* Создание опрерации */}
              {operation.length !== 0 && (
                <div className="col-md-12 col-lg-12 col-xl-12 section-border additional_tab">
                  {operationUser && operationChek !== "" ? (
                    <div className={operationListStyle.black_list}>
                      <h3> Клиент находится в списке - </h3>
                      <h3>
                        {operationUserBlackListType === "forall"
                          ? "Сводный санкционный перечень"
                          : operationUserBlackListType === "ptf"
                          ? "Закрытый перечень физических лиц"
                          : "Перечень лиц преступных доходов"}
                      </h3>
                    </div>
                  ) : (
                    <>
                      {operationChek !== "" && (
                        <>
                          <CreateOperationAdd
                            chekId={operationChek}
                            isInBlckList={operationUser}
                            liveAdd={liveOperation}
                          />
                          <OperationList
                            chekId={operationChek}
                            isInBlckList={operationUser}
                            type={operationUserBlackListType}
                            radioList={operationLive}
                            renderList={createOperationAdd}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
              {/*  */}
            </Modal.Body>
          </Modal>
        )}
      </div>
    </section>
  );
}

export default CreateOperation;
