import axios from "axios";

import { Routes, Route } from "react-router-dom";
import Login from "../auth/login/login";

import "./app.css";
import "../../style/bootstrap.min.css";
import "../../style/main.css";
import "../../style/adminx.css";

import PrivateRoutes from "../utils/PrivateRoutes";
import CreateOperation from "../main/create-operation/create-operation";
import CreateUser from "../main/create-user/create-user";
import UserList from "../main/users-list/users-list";
import HistoryOperation from "../main/history/history";
import UpdateBackList from "../main/update-black-list/update-bl";
import CountriesFilter from "../main/countries/countries-filter";
import Settings from "../main/settings/settings";
import Suspicious from "../main/suspicious/suspicious";
import Shipment from "../main/shipment/shipment";
import { useEffect, useState } from "react";
import Admins from "../main/settings/admins/admins";
import AdminCreate from "../main/settings/admins/create/create";
import ActionLog from "../main/settings/action-log/action-log";
import AdminEdit from "../main/settings/admins/edit/edit";

axios.defaults.baseURL = "https://lk2.bitok.kg/api";

axios.defaults.headers.post["Content-Type"] = "application/json";

axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config, res) {
  const token = localStorage.getItem("bitok_auth_token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

function App() {
  const [country, setCountry] = useState([]);
  const [title, setTitle] = useState("");
  useEffect(() => {
    axios.get("/countries").then((res) => {
      setCountry(...country, res.data);
    });
  }, []);
  return (
    <>
      <Routes>
        {/* Авторизация */}
        <Route path="/login" element={<Login />} exact />
        {/*  */}

        {/* Приватные Route */}
        <Route element={<PrivateRoutes title={title} />}>
          {/* Создать операцию */}
          <Route
            path="/"
            element={
              <CreateOperation country={country} titleChange={setTitle} />
            }
            exact
          />
          {/*  */}
          {/* Создать клиента */}
          <Route
            path="/create-user"
            element={<CreateUser country={country} titleChange={setTitle} />}
            exact
          />
          {/*  */}
          {/* Списко клиентов */}
          <Route
            path="/user-list"
            element={<UserList country={country} titleChange={setTitle} />}
            exact
          />
          {/*  */}
          {/* История операций */}
          <Route
            path="/history-operation"
            element={
              <HistoryOperation country={country} titleChange={setTitle} />
            }
            exact
          />
          {/*  */}
          {/* Черный лист */}
          <Route
            path="/update-black-list"
            element={<UpdateBackList titleChange={setTitle} />}
            exact
          />
          {/*  */}
          {/* Фильтр стран */}
          <Route
            path="/countries-filter"
            element={<CountriesFilter titleChange={setTitle} />}
            exact
          />
          {/*  */}
          {/* Настройки */}
          <Route
            path="/settings"
            element={<Settings titleChange={setTitle} />}
            exact
          />
          {/*  */}
          {/* Пользователи */}
          <Route
            path="/admins"
            element={<Admins titleChange={setTitle} />}
            exact
          />
          <Route
            path="/admins/create"
            element={<AdminCreate titleChange={setTitle} />}
            exact
          />{" "}
          <Route
            path="/admins/edit/:id"
            element={<AdminEdit titleChange={setTitle} />}
            exact
          />
          {/*  */}
          {/* Logs */}
          <Route
            path="/action-logs"
            element={<ActionLog titleChange={setTitle} />}
            exact
          />
          {/*  */}
          {/* Подозрительные */}
          <Route
            path="/suspicious"
            element={<Suspicious titleChange={setTitle} />}
            exact
          />
          {/*  */}
          {/* На отправку */}
          <Route
            path="/shipment"
            element={<Shipment titleChange={setTitle} />}
            exact
          />
          {/*  */}
        </Route>
        {/*  */}
      </Routes>
    </>
  );
}

export default App;
