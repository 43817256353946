import axios from "axios";
import { useEffect, useState } from "react";
import swal from "sweetalert";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

function HistorySearch({ historySearchState }) {
  const [historySearch, sethistorySearch] = useState({
    inn: "",
    name: "",
    country: "",
  });

  const [validated, setValidated] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleInput = (e) => {
    e.persist();
    sethistorySearch({ ...historySearch, [e.target.name]: e.target.value });
  };

  const submithistory = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);
    e.preventDefault();

    const data = {
      pass_num_inn: historySearch.inn,
      from: moment(startDate).format("DD/MM/yyyy")
        ? moment(startDate).format("DD/MM/yyyy")
        : "",
      to: endDate !== null ? moment(endDate).format("DD/MM/yyyy") : "",
      name: historySearch.name,
      country_id: historySearch.country,
    };

    if (validated) {
      axios.post("/user-operations/search", data).then((res) => {
        console.log(res.data);
        historySearchState(res.data);
      });
    }
  };

  return (
    <Form validated={validated} onSubmit={submithistory} id="SEARCHhistoryFORM">
      <Row className="mb-3 align-items-end">
        <div className="col-md-3">
          <Form.Label>По дате</Form.Label>
          <DatePicker
            className="mb-3 w-100 form-control"
            dateFormat="dd/MM/yyyy"
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
          />
        </div>
        <Form.Group
          as={Col}
          md="3"
          className="mb-3 "
          controlId="validationCustom03"
        >
          <Form.Label>ФИО</Form.Label>
          <Form.Control
            type="text"
            value={historySearch.name}
            onChange={handleInput}
            name="name"
            required
          />
        </Form.Group>
        <div className="col-md-3">
          <Button type="submit" className="mb-3 clients__btn">
            Найти
          </Button>
        </div>
      </Row>
    </Form>
  );
}

export default HistorySearch;
