import axios from "axios";
import { useEffect, useState } from "react";
import deleteBtn from "../../../../images/delete.svg";
import swal from "sweetalert";
import showInfo from "../../../../images/eye.png";
import showQr from "../../../../images/qr.png";
import QarCodeModal from "../qr-code-modal/qr-code-modal";
import UserModal from "../../../user-modal/user-modal";
import moment from "moment";
import { currency } from "../../../utils/currency";
import EditOperationComponent from "../../../operation-modal/edit-operation/edit";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

function HistoryTable({ historyList, userHistoryFun }) {
  // Определяет выбор юридического или физического лица
  const toggleValue = useSelector((state) => state.toggle);
  //
  const [role, setRole] = useState(false);

  // Modal State
  const [modalShow, setModalShow] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalShowQr, setModalShowQr] = useState(false);
  //
  const [updateEdit, setUpdateEdit] = useState(false);

  const [qrData, setQrData] = useState();
  const [userData, setUserData] = useState();
  const [operationModalItem, setOperationModalItem] = useState({});
  const [dataQrModal, setDataQrModal] = useState();
  const [qrUpdateList, setQrUpdateList] = useState();
  const [pageCount, setPageCount] = useState(1);

  // Получаем данные всех операций
  useEffect(() => {
    if (toggleValue) {
      axios
        .get("/user-operations", {
          params: {
            type: "legal",
          },
        })
        .then((res) => {
          userHistoryFun(res.data);
          setPageCount(res.data[1].totalPages);
        });
    } else {
      axios
        .get("/user-operations", {
          params: {
            type: "user",
          },
        })
        .then((res) => {
          userHistoryFun(res.data);
          setPageCount(res.data[1].totalPages);
        });
    }
  }, [qrUpdateList, updateEdit, toggleValue]);

  // Определить роль
  useEffect(() => {
    axios.get("/auth/me").then((res) => {
      if (res.data.user === "Admin") {
        setRole(true);
      } else {
        setRole(false);
      }
    });
  }, []);
  //

  // Вывод модального окна для qr код
  const showPopUpQr = (e, id) => {
    e.preventDefault();

    axios.get(`/user-operations/${id}`).then((res) => {
      if (res.status === 200) {
        setQrData(res.data);
        setModalShowQr(true);
      }
    });
  };
  //

  // Удалить пользователя
  const deleteUser = (e, id) => {
    e.preventDefault();
    const thisClicked = e.currentTarget;

    swal({
      title: "Вы уверены что хотите удалить операцию?",
      icon: "warning",
      buttons: ["Отменить", "Да"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios.delete(`/user-operations/${id}`).then((res) => {
          if (res.status === 204) {
            swal("Успешно", "Вы удалили операцию", "success");
            thisClicked.closest("tr").remove();
          }
        });
      }
    });
  };
  //

  const showPopUp = (e, id) => {
    e.preventDefault();
    if (toggleValue) {
      axios.get(`/legal-entities/${id}`).then((res) => {
        if (res.status === 200) {
          setUserData(res.data);
          setModalShow(true);
        }
      });
    } else {
      axios.get(`/added-users/${id}`).then((res) => {
        if (res.status === 200) {
          setUserData(res.data);
          setModalShow(true);
        }
      });
    }
  };

  const handlePageChange = (selectedObject) => {
    if (toggleValue) {
      axios
        .get("/user-operations", {
          params: {
            page: selectedObject,
            type: "legal",
          },
        })
        .then((res) => {
          userHistoryFun(res.data);
        });
    } else {
      axios
        .get("/user-operations", {
          params: {
            page: selectedObject.selected,
            type: "user",
          },
        })
        .then((res) => {
          userHistoryFun(res.data);
        });
    }
  };
  return (
    <section className="history-data-table">
      {/* Модальное окно для просмотра QR кода */}
      <QarCodeModal
        show={modalShowQr}
        onHide={() => setModalShowQr(false)}
        qrData={qrData}
        user={dataQrModal}
        setQrUpdateList={setQrUpdateList}
      />
      {/*  */}
      {/* Модальное окно для просмотра информации */}
      <UserModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        userdata={userData}
      />
      {/*  */}

      {/* Модальное окно для редактирование операций */}
      <EditOperationComponent
        show={modalEdit}
        onHide={() => setModalEdit(false)}
        operation={operationModalItem}
        setUpdateEdit={setUpdateEdit}
      />

      {/*  */}
      <table className="table table-striped table-hover table-history">
        <tbody id="allClientsInfo">
          {/* Описание таблицы */}
          <tr>
            <th>ID</th>
            <th>Дата</th>
            <th>Сумма</th>
            <th>Валюта</th>
            <th>Направление</th>
            <th>Номер кошелька</th>
            <th>ФИО</th>
            <th>Рискованные</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          {/*  */}

          {historyList[0] &&
            historyList[0].map((item, index) => {
              return (
                <tr className="client-data" key={index}>
                  <td>{item.operation_id}</td>
                  <td>
                    {moment(item.operation_date, "DD/MM/YYYY HH:mm").format(
                      "DD.MM.YYYY HH:mm"
                    )}
                  </td>
                  <td>{item.operation_sum}</td>
                  <td>{currency(item.currency)}</td>
                  <td>{item.operation_direction}</td>
                  {/* Кошелек и фото */}
                  <td>
                    {item.wallet_id}
                    {item.attachments !== undefined &&
                    item.attachments !== null &&
                    item.attachments.length !== 0 ? (
                      <button
                        type="button"
                        onClick={(e) => {
                          showPopUpQr(e, item.operation_id);
                          setDataQrModal(item);
                        }}
                        className="show_user_cart_button"
                      >
                        <img src={showQr} />
                      </button>
                    ) : (
                      ""
                    )}
                  </td>
                  {/*  */}
                  {/* Полное ФИО */}
                  <td>
                    {item.fullname ? item.fullname : item.director_full_name}
                  </td>
                  {/*  */}
                  {/* Рискованные */}
                  <td>
                    {item.sanction !== 0 ? (
                      <span className="text-red">Да</span>
                    ) : (
                      <span className="text-green">Нет</span>
                    )}
                  </td>
                  {/*  */}
                  {/* Просмотреть инфо */}
                  <td>
                    <button
                      type="button"
                      onClick={(e) =>
                        showPopUp(e, toggleValue ? item.legal_id : item.user_id)
                      }
                      className="show_user_cart_button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-eye"
                      >
                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                        <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                    </button>
                  </td>
                  {/*  */}
                  {/* Редактировать */}
                  <td>
                    {role ? (
                      <button
                        type="button"
                        onClick={(e) => {
                          setModalEdit(true);
                          setOperationModalItem(item);
                        }}
                        className="delete__button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-edit-3"
                        >
                          <path d="M12 20h9"></path>
                          <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                        </svg>
                      </button>
                    ) : (
                      ""
                    )}
                  </td>
                  {/*  */}
                  {/* Удаление  */}
                  <td>
                    {role ? (
                      <button
                        type="button"
                        onClick={(e) => deleteUser(e, item.operation_id)}
                        className="delete__button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-trash-2"
                        >
                          <polyline points="3 6 5 6 21 6"></polyline>
                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                          <line x1="10" y1="11" x2="10" y2="17"></line>
                          <line x1="14" y1="11" x2="14" y2="17"></line>
                        </svg>
                      </button>
                    ) : (
                      ""
                    )}
                  </td>
                  {/*  */}
                </tr>
              );
            })}
        </tbody>
      </table>

      <ReactPaginate
        pageCount={pageCount}
        onPageChange={handlePageChange}
        containerClassName={"pagination ml-1"}
        previousLinkClassName={"page-link"}
        breakClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextLabel={`>`}
        previousLabel={`<`}
        pageClassName={"page-item"}
        disabledClassNae={"disabled"}
        activeClassName={"active"}
      />
    </section>
  );
}

export default HistoryTable;
