import axios from "axios";

// Users All LiST
let API_URL = "/auth/me";

// Определить роль
const role = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};
//
const roleService = {
  role,
};

export default roleService;
