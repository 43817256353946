import { Modal, Tab, Tabs } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useState } from "react";
import swal from "sweetalert";
import WalletModalComponent from "./wallet/wallet";
import Verification from "./verification/verification";
import { useSelector } from "react-redux";
import LegalInfoComponent from "./info/info-legal";
import LegalDirector from "./director/director";
import InfoStockLegal from "./info-stock/info-stock-legal";

function LegalModal(props) {
  const [editOn, setEditOn] = useState(false);
  const [editInfo, setEdit] = useState({});

  function updateCurrenUserInfo(id) {
    axios
      .put(`/legal-entities/${id}`, editInfo)
      .then((res) => {
        swal("Успешно", "Данные успешно обновились ", "success");
        setEditOn(false);
        props.onHide(true);
      })
      .catch((error) => {
        swal("Ошибка", "Ошибка", "error");
      });
  }

  return props.userdata !== undefined  ? (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-center"
        >
          {props.userdata.verification ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="green"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-check-circle mr-2"
            >
              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
              <polyline points="22 4 12 14.01 9 11.01"></polyline>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="red"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-x-circle"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="15" y1="9" x2="9" y2="15"></line>
              <line x1="9" y1="9" x2="15" y2="15"></line>
            </svg>
          )}
          {`(ID: ${props.userdata.id}) `}
          {props.userdata.name}
        </Modal.Title>
      </Modal.Header>
      {props.userdata.stock === 0 ?
      <Modal.Body>
        <Tabs
          defaultActiveKey="info"
          id="uncontrolled-tab-example"
          className="mb-3"
        >

          <Tab eventKey="info" title="Данные Юр. лица ">
            <LegalInfoComponent
              userdata={props.userdata}
              editOn={editOn}
              setEdit={setEdit}
              editInfo={editInfo}
            />
          </Tab>
          <Tab eventKey="director" title="Данные директора">
            <LegalDirector
              userdata={props.userdata}
              editOn={editOn}
              setEdit={setEdit}
              editInfo={editInfo}
            />
          </Tab>
          <Tab eventKey="verification" title="Верификация">
            <Verification
              userdata={props.userdata}
              editOn={editOn}
              setEdit={setEdit}
              editInfo={editInfo}
            />
          </Tab>
          <Tab eventKey="wallet" title="Кошельки  ">
            <WalletModalComponent wallets={props.userdata.user_operations} />
          </Tab>
        </Tabs>

        <br />
      </Modal.Body>  :
        <Modal.Body>
          <Tabs
              defaultActiveKey="stockInfo"
              id="uncontrolled-tab-example"
              className="mb-3"
          >
            <Tab eventKey="stockInfo" title="Информация по бирже  ">
              <InfoStockLegal
                  info={props.userdata}
                  editOn={editOn}
                  setEdit={setEdit}
                  editInfo={editInfo}
              />
            </Tab>

          </Tabs>

          <br />
        </Modal.Body>}
      <Modal.Footer>
        <Button onClick={(e) => setEditOn(!editOn)}>
          {editOn ? "Отменить" : "Редактировать"}
        </Button>
        <Button
          onClick={(e) => updateCurrenUserInfo(props.userdata.id)}
          className={editOn ? `` : `d-none`}
        >
          Сохранить
        </Button>
      </Modal.Footer>
    </Modal>
  ) : (
    ""
  );
}
export default LegalModal;
