import { useEffect, useState } from "react";
import UserListTable from "./user/user-list-table/user-list-table";
import UserListSearch from "./legal/legal-list-search/legal-list-search";
import { useSelector } from "react-redux";
import LegalListTable from "./legal/legal-list-table/legal-list-table";

function UserList({ country, update, titleChange }) {
  const toggleValue = useSelector((state) => state.toggle);

  // Title страницы передаем в шапку
  useEffect(() => {
    if (toggleValue) {
      titleChange("Юридические лица");
    } else {
      titleChange("Клиенты");
    }
  }, [titleChange, toggleValue]);
  //

  // Определяет выбор юридического или физического лица
  //

  const [userList, setuserList] = useState([]);

  const userListFun = (value) => {
    setuserList(value);
  };

  return (
    <section className="section-border">
      <UserListSearch country={country} userlist={userListFun} />

      {toggleValue ? (
        <LegalListTable
          update={update}
          userList={userList}
          userListFun={userListFun}
          country={country}
        />
      ) : (
        <UserListTable
          update={update}
          userList={userList}
          userListFun={userListFun}
          country={country}
        />
      )}
    </section>
  );
}

export default UserList;
