import React, { useEffect, useState } from "react";
import showQr from "../../../images/qr.png";

import axios from "axios";
import swal from "sweetalert";

import UserModal from "../../user-modal/user-modal";
import moment from "moment";
import { currency } from "../../utils/currency";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

const Suspicious = ({ titleChange }) => {
  // Определяет выбор юридического или физического лица
  const toggleValue = useSelector((state) => state.toggle);
  //
  // Title страницы передаем в шапку
  useEffect(() => {
    titleChange("Подозрительные");
  }, [titleChange]);
  //
  const [userListHome, setuserListHome] = useState([]);
  const [role, setRole] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [userData, setUserData] = useState();
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    if (toggleValue) {
      axios
        .get("/user-operations?risk=1", {
          params: {
            type: "legal",
          },
        })
        .then((res) => {
          setuserListHome(res.data);
          setPageCount(res.data[1].totalPages);
        });
    } else {
      axios
        .get("/user-operations?risk=1", {
          params: {
            type: "user",
          },
        })
        .then((res) => {
          setuserListHome(res.data);
          setPageCount(res.data[1].totalPages);
        });
    }
  }, [toggleValue]);

  const sendToShipment = (e, id) => {
    const thisClicked = e.currentTarget;

    swal({
      title: "Вы уверены?",
      text: "Вы действительно хотите перенести операцию 'На отправку'!",
      icon: "warning",
      buttons: ["Отмена", "Да"],
    }).then((willDelete) => {
      if (willDelete) {
        axios
          .put(`/user-operations/${id}`, {
            sanction: 2,
          })
          .then((res) => {
            if (res.status === 200) {
              swal(`Операция пользователя успешно перенесена!`, {
                icon: "success",
              });
            }
          });
        thisClicked.closest("tr").remove();
      }
    });
  };
  const showPopUp = (e, id) => {
    e.preventDefault();
    if (toggleValue) {
      axios.get(`/legal-entities/${id}`).then((res) => {
        if (res.status === 200) {
          setUserData(res.data);
          setModalShow(true);
        }
      });
    } else {
      axios.get(`/added-users/${id}`).then((res) => {
        if (res.status === 200) {
          setUserData(res.data);
          setModalShow(true);
        }
      });
    }
  };
  useEffect(() => {
    axios.get("/auth/me").then((res) => {
      if (res.data.user === "Admin") {
        setRole(true);
      } else {
        setRole(false);
      }
    });
  }, []);

  const handlePageChange = (selectedObject) => {
    if (toggleValue) {
      axios
        .get("/user-operations?risk=1", {
          params: {
            type: "legal",
            page: selectedObject.selected,
          },
        })
        .then((res) => {
          setuserListHome(res.data);
        });
    } else {
      axios
        .get("/user-operations?risk=1", {
          params: {
            type: "user",
            page: selectedObject.selected,
          },
        })
        .then((res) => {
          setuserListHome(res.data);
        });
    }
  };

  return (
    <section className="history-data-table section-border">
      <UserModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        userdata={userData}
      />
      <table className="table table-striped table-hover table-history">
        <tbody>
          <tr>
            <th> ID</th>
            <th> Дата</th>
            <th> USDT</th>
            <th> Валюта</th>
            <th> Направление</th>
            <th> Номер кошелька</th>
            <th>На отправку</th>
            <th></th>
          </tr>

          {userListHome[0] &&
            userListHome[0].map((item, index) => {
              return (
                <tr className="client-data" key={index}>
                  <td>{item.operation_id}</td>

                  <td>
                    {moment(item.operation_date, "DD.MM.YYYY").format(
                      "DD.MM.YYYY HH:MM"
                    )}
                  </td>
                  <td>{item.operation_sum}</td>
                  <td>{currency(item.currency)}</td>
                  <td>{item.operation_direction}</td>
                  <td>
                    {item.wallet_id}
                    {item.attachments !== undefined &&
                    item.attachments !== null &&
                    item.attachments.length !== 0 ? (
                      <div className="show_user_cart_button">
                        <img src={showQr} />
                      </div>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="cursor-pointer">
                    <svg
                      onClick={(e) => sendToShipment(e, item.operation_id)}
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-send"
                    >
                      <line x1="22" y1="2" x2="11" y2="13"></line>
                      <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                    </svg>
                  </td>
                  <td className={"d-flex justify-content-center"}>
                    <button
                      type="button"
                      onClick={(e) =>
                        showPopUp(e, toggleValue ? item.legal_id : item.user_id)
                      }
                      className="show_user_cart_button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-eye"
                      >
                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                        <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      <ReactPaginate
        pageCount={pageCount}
        onPageChange={handlePageChange}
        containerClassName={"pagination ml-1"}
        previousLinkClassName={"page-link"}
        breakClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextLabel={`>`}
        previousLabel={`<`}
        pageClassName={"page-item"}
        disabledClassNae={"disabled"}
        activeClassName={"active"}
      />
    </section>
  );
};

export default Suspicious;
