import {createAsyncThunk} from "@reduxjs/toolkit";
import legalStockService from "./legal-stock-create-service";

export const legalStockCreate = createAsyncThunk(
    'legalStock/create',
    async (data, thunkAPI)=>{
        try {
            return await legalStockService.legalStockCreate(data)
        } catch (error){
            const message = (error.response && error.response.data ) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
)