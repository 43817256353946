import {useState} from "react";
import { legalStockCreate } from "../../../../store/legal/legal-stock/create-legal-stock-slice"
import {useDispatch} from "react-redux";
import {Col, Form, Row} from "react-bootstrap";
import {FormGroup} from "@mui/material";
import Button from "react-bootstrap/Button";
import swal from "sweetalert";

const CreateLegalStock = () =>{

    const dispatch = useDispatch();
    const [data, setData] = useState({"stock" : 1,});
    const [validated, setValidated] = useState(false)


    const handleInput = (e) => {
        let nameField = e.target.name ? e.target.name : 'empty field';
        switch (nameField){
            case 'name' :
                setData({
                    ...data,
                    'name':e.target.value
                } )
                break;
            case 'address' :
                setData({
                    ...data,
                    'address':e.target.value
                } )
                break;
            case 'IBAN' :
                setData({
                    ...data,
                    'iban':e.target.value
                } )
                break;
            case 'bankAccount' :
                setData({
                    ...data,
                    'bank_account':e.target.value
                } )
                break;
            case 'bankName' :
                setData({
                    ...data,
                    'bank_name':e.target.value
                } )
                break;
            case 'swift' :
                setData({
                    ...data,
                    'swift':e.target.value
                } )
                break;
            case 'accountCode' :
                setData({
                    ...data,
                    'account_code':e.target.value
                } )
                break;
        }
    }
    const handleSubmit = (e) =>{
        e.preventDefault();
        dispatch(legalStockCreate(data)).then((response)=>{
            if (response.payload === 201){
                swal('Биржа успешно добавленна!', {
                    icon : "success",
                })
            }else {
                let errorMessage = "Ошибка при добавлении биржи!"
                if (response.payload.data && response.payload.data.errors) {
                    const errorMessages = Object.values(response.payload.errors).reduce(
                        (acc, val) => acc.concat(val),
                        []
                    );
                    errorMessage = errorMessages.join("\n");
                }
                swal(errorMessage, {
                    icon: "error",
                });
            }
        })
    }
    return (
        <>
            <Form validated={validated} onSubmit={handleSubmit}>
                <Row className={'mb-3'}>
                    <FormGroup
                        as={Col}
                        mb="4"
                        className={'mb-3'}
                        // contrlId={'validationCustom03'}
                    >
                        <Form.Label>Наименование*</Form.Label>
                        <Form.Control
                            type="text"
                            value={data.name}
                            onChange={handleInput}
                            name="name"
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Заполните поле Наименование*
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>
                            Поле успешно заполнено
                        </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup
                        as={Col}
                        mb="4"
                        className={'mb-3'}
                        // contrlId={'validationCustom03'}
                    >
                        <Form.Label>Адрес*</Form.Label>
                        <Form.Control
                            type="text"
                            value={data.address}
                            onChange={handleInput}
                            name="address"
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Заполните поле Адрес*
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>
                            Поле успешно заполнено
                        </Form.Control.Feedback>
                    </FormGroup>
                </Row>
                <Row className='mb-3'>
                    <FormGroup
                        as={Col}
                        mb="4"
                        className={'mb-3'}
                        // contrlId={'validationCustom03'}
                    >
                        <Form.Label>IBAN*</Form.Label>
                        <Form.Control
                            type="text"
                            value={data.IBAN}
                            onChange={handleInput}
                            name="IBAN"
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Заполните поле IBAN*
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>
                            Поле успешно заполнено
                        </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup
                        as={Col}
                        mb="4"
                        className={'mb-3'}
                        // contrlId={'validationCustom03'}
                    >
                        <Form.Label>Банковский счет*</Form.Label>
                        <Form.Control
                            type="text"
                            value={data.bankAccount}
                            onChange={handleInput}
                            name="bankAccount"
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Заполните поле Банковский счет*
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>
                            Поле успешно заполнено
                        </Form.Control.Feedback>
                    </FormGroup>
                </Row>
                <Row className={'mb-3'}>

                    <FormGroup
                        as={Col}
                        mb="4"
                        className={'mb-3'}
                        // contrlId={'validationCustom03'}
                    >
                        <Form.Label>Наименования банка*</Form.Label>
                        <Form.Control
                            type="text"
                            value={data.bankName}
                            onChange={handleInput}
                            name="bankName"
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Заполните поле Наименования банка*
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>
                            Поле успешно заполнено
                        </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup
                        as={Col}
                        mb="4"
                        className={'mb-3'}
                        // contrlId={'validationCustom03'}
                    >
                        <Form.Label>Swift*</Form.Label>
                        <Form.Control
                            type="text"
                            value={data.swift}
                            onChange={handleInput}
                            name="swift"
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Заполните поле Swift*
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>
                            Поле успешно заполнено
                        </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup
                        as={Col}
                        mb="4"
                        className={'mb-3'}
                        // contrlId={'validationCustom03'}
                    >
                        <Form.Label>Код аккаунта*</Form.Label>
                        <Form.Control
                            type="text"
                            value={data.accountCode}
                            onChange={handleInput}
                            name="accountCode"
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Заполните поле Код аккаунта*
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>
                            Поле успешно заполнено
                        </Form.Control.Feedback>
                    </FormGroup>
                </Row>
                <Button type="submit" className="mt-1">
                    Создать
                </Button>
            </Form>
        </>
    )
}
export default CreateLegalStock;