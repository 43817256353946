import axios from "axios";
import { useEffect, useState } from "react";
import swal from "sweetalert";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";

function UserListSearch({ userlist }) {
  const toggleValue = useSelector((state) => state.toggle);

  const [userCreate, setUser] = useState({
    clientfio: "",
    clientRegDate: "",
  });

  const [validated, setValidated] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const onChange = (dates) => {
    const [start, end] = dates;
    let startDate = start
    startDate === null ? startDate = '' : startDate=startDate
    setStartDate(startDate);
    setEndDate(end);
    setUser({ ...userCreate, clientRegDate: startDate});
  };

  const handleInput = (e) => {
    e.persist();
    setUser({ ...userCreate, [e.target.name]: e.target.value });
  };

  useEffect(()=>{
    console.log(userCreate)
    if (userCreate.clientfio === "" && userCreate.clientRegDate === "") {
      setValidated(false);
      // e.stopPropagation();
    }else if(userCreate.clientfio !== "" || userCreate.clientRegDate !== ""){
      setValidated(true);
    }
  },[userCreate])

  const submitClient = (e) => {
    console.log(userCreate.clientfio)

    e.preventDefault();

    const data = {
      type: toggleValue ? "legal" : "user",
    }
    if (userCreate.clientfio !== ''){
      data.name = userCreate.clientfio
    }

    if (startDate !== null ){
      data.from = moment(startDate).format("DD/MM/yyyy")
    }
    if (endDate !== null ){
      data.to = moment(endDate).format("DD/MM/yyyy")
    }
    console.log(validated)

    if (validated) {
      const apiUrlType = toggleValue ? "clients" : "clients";
      console.log('valid')
      axios.post(`/${apiUrlType}/search`, data).then((res) => {
        console.log(res.data)
        const arrayFormat = []
        arrayFormat.push(res.data)
        arrayFormat.push("noPagination")
        console.log(arrayFormat)
        userlist(arrayFormat);
      });
    }if(!validated){
      console.log('no valid')
      const apiUrlType = toggleValue ? "legal-entities" : "added-users";
      // axios.post(`/${apiUrlType}/search`, data).then((res) => {
      //   console.log(res)
      //   const arrayFormat = []
      //   arrayFormat.push(res.data)
      //   arrayFormat.push("noPagination")
      //   userlist(arrayFormat);
      // });
      axios.get(`/${apiUrlType}?page=1`).then((res) => {
        userlist(res.data);
      });
    }
  };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={submitClient}
        id="SEARCHCLIENTFORM"
      >
        <Row className="mb-3 align-items-end">
          <Form.Group
            as={Col}
            md="3"
            className="mb-3"
            controlId="validationCustom03"
          >
            <Form.Label>ФИО</Form.Label>
            <Form.Control
              type="text"
              value={userCreate.clientfio}
              onChange={handleInput}
              name="clientfio"
              required
            />
          </Form.Group>

          <div className="col-md-3">
            <Form.Label>По дате</Form.Label>
            <DatePicker
              className="mb-3 w-100 form-control"
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
            />
          </div>
          <div className="col-md-3">
            <Button type="submit" className="mb-3 clients__btn">
              Найти
            </Button>
          </div>
        </Row>
      </Form>
    </>
  );
}

export default UserListSearch;
