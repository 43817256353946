import React, { useEffect } from "react";
import SettingMenu from "../menu/menu";
import axios from "axios";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import Form from "react-bootstrap/Form";
import { Button, Row } from "react-bootstrap";
import moment from "moment";

function ActionLog({ titleChange }) {
  const [logs, setLogs] = useState([]);
  const [pageCount, setPageCount] = useState(1);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // Title страницы передаем в шапку
  useEffect(() => {
    titleChange("Логи");
  }, [titleChange]);
  //

  useEffect(() => {
    axios.get("/logs").then((res) => {
      setLogs(res.data[0]);
      setPageCount(res.data[1].totalPages);
    });
  }, []);

  const handlePageChange = (selectedObject) => {
    axios
      .get("/logs", {
        params: {
          page: selectedObject.selected,
        },
      })
      .then((res) => {
        setLogs(res.data[0]);
      });
  };

  // Фильтр по дате

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const submitLog = (e) => {
    e.preventDefault();

    const start_date = startDate ? moment(startDate).format("DD/MM/yyyy") : "";
    const end_date = endDate ? moment(endDate).format("DD/MM/yyyy") : "";

    const params = {};
    if (start_date) {
      params.start_date = start_date;
    }
    if (end_date) {
      params.end_date = end_date;
    }

    axios
      .get("/logs", {
        params: params,
      })
      .then((res) => {
        setLogs(res.data[0]);
      });
  };
  console.log(logs);
  return (
    <section className="section-border">
      <SettingMenu />
      {/*  */}
      <Form onSubmit={submitLog}>
        <Row className="mb-3 align-items-end">
          <div className="col-md-3">
            <Form.Label>По дате</Form.Label>
            <DatePicker
              className="mb-3 w-100 form-control"
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
            />
          </div>
          <div className="col-md-3">
            <Button type="submit" className="mb-3 clients__btn">
              Найти
            </Button>
          </div>
        </Row>
      </Form>
      <table className="table table-striped table-hover table-history">
        <tbody>
          {logs &&
            logs.map((item, index) => {
              return (
                <tr className="client-data" key={index}>
                  <td>{index + 1}</td>
                  <td>
                    {item.date} | {item.author_email} | {item.description}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <ReactPaginate
        pageCount={pageCount}
        onPageChange={handlePageChange}
        containerClassName={"pagination ml-1"}
        previousLinkClassName={"page-link"}
        breakClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextLabel={`>`}
        previousLabel={`<`}
        pageClassName={"page-item"}
        disabledClassNae={"disabled"}
        activeClassName={"active"}
      />
    </section>
  );
}

export default ActionLog;
