import React from "react";
import modalStyles from "./user-cart-modal.module.css";
import Form from "react-bootstrap/Form";
import {useSelector} from "react-redux";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {countryData} from "../../../store/country/country-slice";
import "photoswipe/dist/photoswipe.css";

import {Gallery, Item} from "react-photoswipe-gallery";
import moment from "moment";
import DatePicker from "react-flatpickr";

function InfoComponent({editOn, setEdit, editInfo, userdata}) {
    const dispatch = useDispatch();
    const {country} = useSelector((state) => state.country);

    useEffect(() => {
        dispatch(countryData());
    }, [editOn]);
    return (
        <>
            <ul className="p-0">
                {/* Фамилия */}
                <li className={`${modalStyles.user_info_item}`}>
                    <b>Фамилия : </b>
                    <span
                        className={
                            editOn ? "form-control w-auto" : modalStyles.user__editinput
                        }
                        contentEditable={editOn ? "true" : "false"}
                        onInput={(e) => {
                            setEdit({...editInfo, last_name: e.target.innerText});
                        }}
                    >
                        {userdata.last_name && userdata.last_name !== "undefined"
                            ? userdata.last_name
                            : ""}
                      </span>
                </li>
                {/*  */}

                {/* ИМя */}
                <li className={`${modalStyles.user_info_item}`}>
                    <b>Имя : </b>
                    <span
                        className={
                            editOn ? "form-control w-auto" : modalStyles.user__editinput
                        }
                        contentEditable={editOn ? "true" : "false"}
                        onInput={(e) => {
                            setEdit({...editInfo, first_name: e.target.innerText});
                        }}
                    >
                    {userdata.first_name && userdata.first_name !== "undefined"
                        ? userdata.first_name
                        : ""}
                    </span>
                </li>
                {/*  */}

                {/* Отчество */}
                <li className={`${modalStyles.user_info_item}`}>
                    <b>Отчество : </b>
                    <span
                        className={
                            editOn ? "form-control w-auto" : modalStyles.user__editinput
                        }
                        contentEditable={editOn ? "true" : "false"}
                        onInput={(e) => {
                            setEdit({...editInfo, middle_name: e.target.innerText});
                        }}
                    >
            {userdata.middle_name && userdata.middle_name !== "undefined"
                ? userdata.middle_name
                : ""}
          </span>
                </li>
                {/*  */}

                {/* Дата рождение */}
                <li className={`${modalStyles.user_info_item}`}>
                    <b>Дата рождения : </b>
                    {/* <span
            className={
              editOn ? "form-control w-auto" : modalStyles.user__editinput
            }
            contentEditable={editOn ? "true" : "false"}
            onInput={(e) => {
              setEdit({ ...editInfo, birth_date: e.target.innerText });
            }}
          >
            {userdata.birth_date}
          </span> */}
                    {editOn ? (
                        <DatePicker
                            className=" w-50  bg-white form-control"
                            defaultValue={moment(userdata.birth_date, "DD/MM/YYYY").format(
                                "YYYY-MM-DD"
                            )}
                            onChange={(date) => {
                                const selectedDate = new Date(date).toLocaleDateString();
                                setEdit({
                                    ...editInfo,
                                    birth_date: moment(selectedDate, "DD-MM-YYYY").format(
                                        "DD/MM/YYYY"
                                    ),
                                });
                            }}
                        />
                    ) : (
                        <span className={modalStyles.user__editinput}>
              {userdata.birth_date}
            </span>
                    )}
                </li>
                {/*  */}
                {/* Страна */}
                <li className={`${modalStyles.user_info_item}`}>
                    <b>Страна :</b>

                    {editOn ? (
                        <Form.Select
                            name="country_id"
                            onChange={(e) => {
                                setEdit({...editInfo, country_id: parseInt(e.target.value)});
                            }}
                            className="w-50"
                            defaultValue={userdata.country_id}
                        >
                            <option value="">Выберите страну</option>
                            {country &&
                            country.length !== 0 &&
                            country.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}>
                                        {item.name}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    ) : (
                        <span
                            className={
                                editOn ? "form-control w-auto" : modalStyles.user__editinput
                            }
                        >
              {userdata.country}
            </span>
                    )}
                </li>
                {/*  */}
                {/* Дата регистрации */}
                <li className={`${modalStyles.user_info_item}`}>
                    <b>Дата регистрации :</b>
                    <span
                        contentEditable={editOn ? "true" : "false"}
                        onInput={(e) => {
                            setEdit({...editInfo, registration_date: e.target.innerText});
                        }}
                        className={
                            editOn ? "form-control w-auto" : modalStyles.user__editinput
                        }
                    >
            {userdata.registration_date}
          </span>
                </li>
                {/*  */}
                {/* Черный список */}
                <li className={`${modalStyles.user_info_item}`}>
                    <b>Черный список : </b>{" "}
                    <span className={modalStyles.user__editinput}>
            {userdata.black_list === false
                ? "Отсутствует"
                : userdata.black_list}
          </span>
                </li>
                {/*  */}
                {userdata.country === "Кыргызстан" && (
                    <li className={`${modalStyles.user_info_item}`}>
                        <b>ИНН :</b>
                        <span
                            contentEditable={editOn ? "true" : "false"}
                            onInput={(e) => {
                                setEdit({...editInfo, pass_num_inn: e.target.innerText});
                            }}
                            className={
                                editOn ? "form-control w-auto" : modalStyles.user__editinput
                            }
                        >
              {userdata.pass_num_inn}
            </span>
                    </li>
                )}
                {userdata.country !== "Кыргызстан" ? (
                    <>
                        {/* Паспорт ID */}
                        <li className={`${modalStyles.user_info_item}`}>
                            <b> Паспорт :</b>
                            <span
                                contentEditable={editOn ? "true" : "false"}
                                onInput={(e) => {
                                    setEdit({
                                        ...editInfo,
                                        passport_id: e.target.innerText,
                                    });
                                }}
                                className={
                                    editOn ? "form-control w-auto" : modalStyles.user__editinput
                                }
                            >
                {userdata.passport_id}
              </span>
                        </li>
                        {/*  */}

                        {/* Кто выдал */}
                        <li className={`${modalStyles.user_info_item}`}>
                            <b>Кто выдал :</b>
                            <span
                                contentEditable={editOn ? "true" : "false"}
                                onInput={(e) => {
                                    setEdit({
                                        ...editInfo,
                                        passport_authority: e.target.innerText,
                                    });
                                }}
                                className={
                                    editOn ? "form-control w-auto" : modalStyles.user__editinput
                                }
                            >
                {userdata.passport_authority}
              </span>
                        </li>
                        {/*  */}

                        {/* Код-подразделения */}
                        <li className={`${modalStyles.user_info_item}`}>
                            <b>Код-подразделения :</b>
                            <span
                                contentEditable={editOn ? "true" : "false"}
                                onInput={(e) => {
                                    setEdit({
                                        ...editInfo,
                                        passport_authority_code: e.target.innerText,
                                    });
                                }}
                                className={
                                    editOn ? "form-control w-auto" : modalStyles.user__editinput
                                }
                            >
                {userdata.passport_authority_code}
              </span>
                        </li>
                        {/*  */}

                        {/* Дата выдачи паспорта */}
                        <li className={`${modalStyles.user_info_item}`}>
                            <b>Дата выдачи паспорта :</b>
                            <span
                                contentEditable={editOn ? "true" : "false"}
                                onInput={(e) => {
                                    setEdit({
                                        ...editInfo,
                                        passport_expires_at: e.target.innerText,
                                    });
                                }}
                                className={
                                    editOn ? "form-control w-auto" : modalStyles.user__editinput
                                }
                            >
                {userdata.passport_expires_at}
              </span>
                        </li>
                        {/*  */}

                        {/* Дата окончания актульности паспорта */}
                        <li className={`${modalStyles.user_info_item}`}>
                            <b>Дата окончания актуальности паспорта :</b>
                            <span
                                contentEditable={editOn ? "true" : "false"}
                                onInput={(e) => {
                                    setEdit({
                                        ...editInfo,
                                        passport_expires_at: e.target.innerText,
                                    });
                                }}
                                className={
                                    editOn ? "form-control w-auto" : modalStyles.user__editinput
                                }
                            >
                {userdata.passport_issued_at}
              </span>
                        </li>
                        {/*  */}

                        {/* Уровень риска */}
                        <li className={`${modalStyles.user_info_item}`}>
                            <b>Уровень риска :</b>
                            {userdata.sanction !== 0 ? (
                                <span className={`${modalStyles.user__editinput} text-red`}>
                  Рискованные
                </span>
                            ) : (
                                <span className={modalStyles.user__editinput}>Обычные</span>
                            )}
                        </li>
                        {/*  */}
                    </>
                ) : (
                    ""
                )}
            </ul>
            {/* Фото паспорта */}
            <div className="row">
                <Gallery>
                    {userdata.attachments.map((attachment, index) => {
                        if (attachment.type === "passport_photo"  || attachment.type === "passport") {
                            return (
                                <Item
                                    key={index}
                                    original={`https://${attachment.url}`}
                                    thumbnail={`https://${attachment.thumbnail_url}`}
                                    width="1024"
                                    height="768"
                                >
                                    {({ref, open}) => (
                                        <div className="col-lg-4 text-center" key={index}>
                                            <div className="user__cart--block">
                                                <img
                                                    className={"user_cart_img"}
                                                    src={`https://${attachment.url && attachment.url}`}
                                                />
                                                <div className="page-file__option" data-test={ref}>
                                                    <button
                                                        type="button"
                                                        className="page-file__zoom"
                                                        ref={ref}
                                                        onClick={open}
                                                    ></button>
                                                    <button
                                                        type="button"
                                                        className="page-file__delete"
                                                    ></button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Item>
                            );
                        }
                    })}
                </Gallery>
            </div>
            {/*  */}
        </>
    );
}

export default InfoComponent;
