import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import SettingMenu from "../menu/menu";
import { useState } from "react";
import axios from "axios";
import swal from "sweetalert";

function Admins({ titleChange }) {
  const [admins, setAdmins] = useState([]);
  // Title страницы передаем в шапку
  useEffect(() => {
    titleChange("Пользователи");
  }, [titleChange]);
  //

  useEffect(() => {
    axios.get("/users").then((res) => {
      setAdmins(res.data);
    });
  }, []);

  // Удаление пользователя
  const deleteAdmin = (e, id) => {
    const thisClicked = e.currentTarget;

    swal({
      title: "Вы уверены что хотите удалить операцию?",
      icon: "warning",
      buttons: ["Отменить", "Да"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios.delete(`/users/${id}`).then((res) => {
          if (res.status === 204) {
            swal("Успешно", "Вы удалили операцию", "success");
            thisClicked.closest("tr").remove();
          }
        });
      }
    });
  };
  //

  return (
    <section className="section-border">
      <SettingMenu />

      <Link to="/admins/create" className="mb-3 clients__btn btn btn-primary">
        Добавить пользователя
      </Link>
      {/*  */}
      <table className="table table-striped table-hover table-history">
        <tbody>
          {/* Описание таблицы */}
          <tr>
            <th>ID</th>
            <th>E-mail</th>
            <th>Имя</th>
            <th>Роль</th>
            <th>Статус активности </th>
            <th></th>
            <th></th>
          </tr>
          {/*  */}
          {admins &&
            admins.map((item) => {
              return (
                <tr className="client-data" key={1}>
                  <td>{item.id}</td>
                  <td>{item.email}</td>
                  <td>{item.name}</td>
                  <td>{item?.role}</td>
                  {/* Просмотреть инфо */}
                  <td>
                    {item?.status ? (
                      <span className="text-green">Да</span>
                    ) : (
                      <span className="text-red">Нет</span>
                    )}
                  </td>
                  {/*  */}
                  {/* Редактировать */}
                  <td>
                    <Link to={`/admins/edit/${item.id}`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-edit-3"
                      >
                        <path d="M12 20h9"></path>
                        <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                      </svg>
                    </Link>
                  </td>
                  {/*  */}
                  {/* Удаление  */}
                  <td>
                    {item.id === 1 ? (
                      ""
                    ) : (
                      <button
                        type="button"
                        onClick={(e) => deleteAdmin(e, item.id)}
                        className="delete__button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-trash-2"
                        >
                          <polyline points="3 6 5 6 21 6"></polyline>
                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                          <line x1="10" y1="11" x2="10" y2="17"></line>
                          <line x1="14" y1="11" x2="14" y2="17"></line>
                        </svg>
                      </button>
                    )}
                  </td>
                  {/*  */}
                </tr>
              );
            })}
        </tbody>
      </table>
    </section>
  );
}

export default Admins;
