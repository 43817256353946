import React from "react";
import modalStyles from "./user-cart-modal.module.css";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { countryData } from "../../../store/country/country-slice";
import "photoswipe/dist/photoswipe.css";
import pdf_icon from "../../../images/pdf_icon.svg"

import { Gallery, Item } from "react-photoswipe-gallery";

function LegalInfoComponent({ editOn, setEdit, editInfo, userdata }) {
  const dispatch = useDispatch();
  const { country } = useSelector((state) => state.country);

  useEffect(() => {
    dispatch(countryData());
  }, [editOn]);


  return (
    <>
      <ul className="p-0">
        {/* НАИМЕНОВАНИЕ */}
        <li className={`${modalStyles.user_info_item}`}>
          <b>Наименование : </b>
          <span
            className={
              editOn ? "form-control w-auto" : modalStyles.user__editinput
            }
            contentEditable={editOn ? "true" : "false"}
            onInput={(e) => {
              setEdit({ ...editInfo, name: e.target.innerText });
            }}
          >
            {userdata.name}
          </span>
        </li>
        {/*  */}

        {/* Страна регистрации */}
        <li className={`${modalStyles.user_info_item}`}>
          <b>Страна регистрации:</b>

          {editOn ? (
            <Form.Select
              name="country_id"
              onChange={(e) => {
                setEdit({ ...editInfo, country_id: parseInt(e.target.value) });
              }}
              className="w-50"
              defaultValue={userdata.country_id}
            >
              <option value="">Выберите страну</option>
              {country &&
                country.length !== 0 &&
                country.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </Form.Select>
          ) : (
            <span
              className={
                editOn ? "form-control w-auto" : modalStyles.user__editinput
              }
            >
              {userdata.country}
            </span>
          )}
        </li>
        {/*  */}

        {/* Адрес регистрации */}
        <li className={`${modalStyles.user_info_item}`}>
          <b>Адрес регистрации : </b>
          <span
            className={
              editOn ? "form-control w-auto" : modalStyles.user__editinput
            }
            contentEditable={editOn ? "true" : "false"}
            onInput={(e) => {
              setEdit({ ...editInfo, name: e.target.innerText });
            }}
          >
            {userdata.name}
          </span>
        </li>
        {/*  */}

        {userdata.country !== "Кыргызстан" ? (
          <>
            {/* Уровень риска */}
            <li className={`${modalStyles.user_info_item}`}>
              <b>Уровень риска :</b>
              {userdata.sanction !== 0 ? (
                <span className={`${modalStyles.user__editinput} text-red`}>
                  Рискованные
                </span>
              ) : (
                <span className={modalStyles.user__editinput}>Обычные</span>
              )}
            </li>
            {/*  */}
          </>
        ) : (
          ""
        )}
      </ul>

      {/* Фото паспорта */}
      <div className="row">
        <Gallery>
          {userdata.attachments.map((attachment, index) => {
            const parts = attachment?.url.split('/');
            const lastPart = parts[parts.length - 1];
            const fileFormat = lastPart.substring(lastPart.lastIndexOf('.'));
            console.log(fileFormat)
            if (attachment.type === "certificate_photo" && fileFormat !== '.pdf') {
              return (
                <Item
                  original={`https://${attachment?.url}`}
                  thumbnail={`https://${attachment?.url}`}
                  width="1024"
                  height="768"
                  key={index}
                >
                  {({ ref, open }) => (
                    <div className="col-lg-4 text-center" ref={ref}>
                      <div className="user__cart--block">
                        <img
                          className={"user_cart_img"}
                          src={`https://${attachment?.url}`}
                        />
                        <div className="page-file__option">
                          <button
                            type="button"
                            className="page-file__zoom"
                            onClick={open}
                          ></button>
                          <button
                            type="button"
                            className="page-file__delete"
                          ></button>
                        </div>
                      </div>
                    </div>
                  )}
                </Item>
              );
            }
            else if (attachment.type === "certificate_photo" && fileFormat === '.pdf') {
              return (
                        <div className="col-lg-4 text-center" >
                          <div  className="user__cart--block link_to_pdf">
                            <img
                                className={"user_cart_img "}
                                src={pdf_icon}
                            />
                            <div className="page-file__option">
                              <a
                                  target={'_blank'}
                                  href={`https://${attachment?.url}`}
                                  type="button"
                                  className="page-file__zoom pdf"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 20h16m-8-6V4m0 10l4-4m-4 4l-4-4"/></svg>
                              </a>
                              <button
                                  type="button"
                                  className="page-file__delete"
                              ></button>
                            </div>
                          </div>
                        </div>
              )
            }
            else if (attachment.type === "permit_photo" && fileFormat !== '.pdf') {
              return (
                <Item
                  original={`https://${attachment?.url}`}
                  thumbnail={`https://${attachment?.url}`}
                  width="1024"
                  height="768"
                  key={index}
                >
                  {({ ref, open }) => (
                    <div className="col-lg-4 text-center" ref={ref}>
                      <div className="user__cart--block">
                        <img
                          className={"user_cart_img"}
                          src={`https://${attachment?.url}`}
                        />
                        <div className="page-file__option">
                          <button
                            type="button"
                            className="page-file__zoom"
                            onClick={open}
                          ></button>
                          <button
                            type="button"
                            className="page-file__delete"
                          ></button>
                        </div>
                      </div>
                    </div>
                  )}
                </Item>
              );
            }
            else if (attachment.type === "permit_photo" && fileFormat === '.pdf') {
              return (
                  <div className="col-lg-4 text-center" >
                    <div  className="user__cart--block link_to_pdf">
                      <img
                          className={"user_cart_img "}
                          src={pdf_icon}
                      />
                      <div className="page-file__option">
                        <a
                            target={'_blank'}
                            href={`https://${attachment?.url}`}
                            type="button"
                            className="page-file__zoom pdf"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 20h16m-8-6V4m0 10l4-4m-4 4l-4-4"/></svg>
                        </a>
                        <button
                            type="button"
                            className="page-file__delete"
                        ></button>
                      </div>
                    </div>
                  </div>
              )
            }
            else if (attachment.type === "licence_photo" && fileFormat !== '.pdf' ) {
              console.log(attachment?.url)
              return (
                <Item
                  original={`https://${attachment?.url}`}
                  thumbnail={`https://${attachment?.url}`}
                  width="1024"
                  height="768"
                  key={index}
                >
                  {({ ref, open }) => (
                    <div className="col-lg-4 text-center" ref={ref}>
                      <div className="user__cart--block">
                        <img
                          className={"user_cart_img"}
                          src={`https://${attachment?.url}`}
                        />
                        <div className="page-file__option">
                          <button
                            type="button"
                            className="page-file__zoom"
                            onClick={open}
                          ></button>
                          <button
                            type="button"
                            className="page-file__delete"
                          ></button>
                        </div>
                      </div>
                    </div>
                  )}
                </Item>
              );
            }
            else if (attachment.type === "licence_photo" && fileFormat === '.pdf') {
              return (
                  <div className="col-lg-4 text-center" >
                    <div  className="user__cart--block link_to_pdf">
                      <img
                          className={"user_cart_img "}
                          src={pdf_icon}
                      />
                      <div className="page-file__option">
                        <a
                            target={'_blank'}
                            href={`https://${attachment?.url}`}
                            type="button"
                            className="page-file__zoom pdf"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 20h16m-8-6V4m0 10l4-4m-4 4l-4-4"/></svg>
                        </a>
                        <button
                            type="button"
                            className="page-file__delete"
                        ></button>
                      </div>
                    </div>
                  </div>
              )
            }
            else {
              return null;
            }
          })}
        </Gallery>
      </div>
      {/*  */}
    </>
  );
}

export default LegalInfoComponent;
