import { Link } from "react-router-dom";

import { Form } from "react-bootstrap";
import styleList from "./list.module.css";
function CreateOperationList({
  list,
  check,
  user_bl,
  live,
  type_bl,
  modalShow,
  user,
  userFindValue,
}) {
  const hadleChange = (e, item) => {
    check(e.target.value);
    user_bl(
      e.target.getAttribute("data-user_bl") === "true"
        ? Boolean(true)
        : Boolean(false)
    );
    type_bl(e.target.getAttribute("data-bl_type"));
    live();
    modalShow(true);
    user(item);
  };
  return list && list.length !== 0 ? (
    <section className={`${styleList.history_overflow} history-data-table`}>
      <table className="table table-striped table-hover table-history">
        <tbody id="allClientsInfo">
          <tr>
            <th> </th>
            <th> Фамилия</th>
            <th> Имя</th>
            <th> Отчество</th>
            <th> Дата рождения</th>
            <th> ИНН</th>
            <th> Дата регистрации</th>
          </tr>

          {list.map((item, index) => {
            return (
              <tr className="client-data" key={index}>
                <td className="clients-check">
                  <Form.Check
                    name={`operation-checkbox`}
                    type={"radio"}
                    id={item.id}
                    data-user_bl={item.black_list}
                    data-bl_type={item.type}
                    value={item.id}
                    onChange={(e) => hadleChange(e, item)}
                    checked={false}
                    className="d-flex justify-content-center create__operation--radio"
                  />
                </td>
                <td>{item.last_name}</td>
                <td>{item.first_name}</td>
                <td>{item.middle_name}</td>
                <td>{item.birth_date}</td>
                <td>{item.pass_num_inn}</td>
                <td>{item.registration_date}</td>
                <td className={styleList.d_none}>
                  {item.black_list === true ? true : false}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <span>
        <Link to={"create-user"} className="btn btn-primary">
          Создать клиента
        </Link>
      </span>
    </section>
  ) : (
    <>
      {userFindValue === "" ? (
        ""
      ) : (
        <>
          "Клиент не найден!"
          <Link to={"create-user"}>Создать клиента</Link>
        </>
      )}
    </>
  );
}

export default CreateOperationList;
