import { useEffect, useState } from "react";
import HistorySearch from "./history-search/history-search";
import HistoryTable from "./history-table/history-table";

function HistoryOperation({ update, titleChange }) {
  // Title страницы передаем в шапку
  useEffect(() => {
    titleChange("История операций");
  }, [titleChange]);
  //
  const [historyList, setHistoryList] = useState([]);

  const userHistoryFun = (value) => {
    setHistoryList(value);
  };

  return (
    <section className="section-border">
      <HistorySearch historySearchState={userHistoryFun} />
      <HistoryTable
        update={update}
        historyList={historyList}
        userHistoryFun={userHistoryFun}
      />
    </section>
  );
}

export default HistoryOperation;
