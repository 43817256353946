import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function OperationList({ chekId, isInBlckList, renderList, radioList }) {
  // Определяет выбор юридического или физического лица
  const toggleValue = useSelector((state) => state.toggle);
  //
  const [listOperation, setlistOperation] = useState([]);
  useEffect(() => {
    if (chekId !== "") {
      if (isInBlckList === false) {
        if (toggleValue) {
          axios
            .get(`/added-users/${chekId}/user-operations`, {
              params: {
                type: "legal",
              },
            })
            .then((res) => {
              if (res.status === 200) {
                setlistOperation(res.data[0]);
              }
              if (res.status === 404) {
                console.log("ok");
              }
            })
            .catch((err) => {
              console.log(err);
              setlistOperation([]);
            });
        } else {
          axios
            .get(`/added-users/${chekId}/user-operations`, {
              params: {
                type: "user",
              },
            })
            .then((res) => {
              if (res.status === 200) {
                setlistOperation(res.data[0]);
              }
              if (res.status === 404) {
                console.log("ok");
              }
            })
            .catch((err) => {
              console.log(err);
              setlistOperation([]);
            });
        }
      }
    }
  }, [renderList, chekId, radioList]);

  return listOperation.length !== 0 ? (
    <section className="history-data-table">
      <p>История операций</p>
      <table className="table table-striped table-hover table-history">
        <tbody id="allClientsInfo">
          <tr>
            <th> Дата</th>
            <th> Сумма </th>
            <th> Валюта </th>
            <th> Направление</th>
            <th> Номер кошелька </th>
          </tr>
          {listOperation.map((item, index) => {
            return (
              <tr className="client-data" key={`${index}-operation`}>
                <td>{item.operation_date}</td>
                <td>{item.operation_sum}</td>
                <td>{item.currency}</td>
                <td>{item.operation_direction}</td>
                <td>{item.wallet_id}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  ) : (
    "Нет операций"
  );
}
export default OperationList;
