import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import blFormStyle from "./upload-bl-form.module.css";
import swal from "sweetalert";
import axios from "axios";

function UploadBlForm({ blData, updateDate, getFile, getBlName, getBlStatus }) {
  const blackListCategories = [
    {
      name: "empty",
      fullName: "Выберете ЧС",
    },
    {
      name: "pft",
      fullName: "Перечень физических лиц...(ПФТ)",
    },
    {
      name: "plpd",
      fullName: "Перечень лиц, групп, организаций...(ПЛПД)",
    },
    {
      name: "forall",
      fullName: "Сводный санкционный перечень Кыргызской Республики",
    },
    {
      name: "un",
      fullName: "Сводный санкционный перечень Совета Безопасности ООН",
    },
    {
      name: "plpdLegal",
      fullName:
        "Сводный санкционный перечень юридических лиц Кыргызской Республики (ПЛПД)",
    },
  ];

  const [type, setName] = useState("");
  const [file, setSelectedFile] = useState();
  const submitBlForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    blData([]);
    if (file !== "" && type !== "" && type !== undefined) {
      if (type === "empty") {
        swal("Ошибка", "Выберете ЧС", "error");
      } else {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", type);
        for (let key of formData.entries()) {
          console.log(key[0] + ", " + key[1]);
        }
        try {
          axios
            .post("/import", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              console.log(res);
              if (res.status === 200 || res.status === 201) {
                swal("Успех", "ЧС успешно обновлен", "success");
                blData = res.data;
                updateDate(res.data.date);
                getFile(res.data.file);
                getBlName(res.data.black_list_name);
                getBlStatus(res.data.status);
              } else {
                console.log(res.status);
                swal("Ошибка", "При обновлении ЧС произошла ошибка", "error");
              }
            });
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      swal("Ошибка", "Все поля обязательны для заполнения", "error");
    }
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <div className="row justify-content-start">
      <Form
        className={`${blFormStyle.d_flex}`}
        id={"update_bl"}
        onSubmit={submitBlForm}
      >
        <Form.Group as={Col} md={"4"}>
          <Form.Control
            type={"file"}
            onChange={handleFileSelect}
            name="blackListFile"
            required
          />
          <Form.Control.Feedback>Поле успешно заполнено</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md={"6"} sm={"12"}>
          <Form.Select
            name="type"
            value={type}
            onChange={(e) => setName(e.target.value)}
          >
            {blackListCategories.map((item, index) => {
              return (
                <option key={index} value={item.name}>
                  {item.fullName}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        <Button type="submit">Обработать</Button>
      </Form>
    </div>
  );
}

export default UploadBlForm;
