import axios from "axios";
import { useEffect, useState } from "react";
import swal from "sweetalert";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import moment from "moment";
import createUserFormStyles from "./create-user-form.module.css";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { countryData } from "../../../../store/country/country-slice";

function UserCreateForm() {
  const dispatch = useDispatch();
  const { country } = useSelector((state) => state.country);

  useEffect(() => {
    dispatch(countryData());
  }, [dispatch]);
  const [userCreate, setUser] = useState({});

  const [validated, setValidated] = useState(false);

  const [isKgz, setIsKgz] = useState("d-none");
  const [isOtherLand, setOtherland] = useState("");
  const [isInblList, setisInblList] = useState(false);
  const [isVerified, setisVerified] = useState(0);
  const [passport_photo, setSelectedFile] = useState();
  const [passport_photo2, setSelectedFile2] = useState();
  const [riskUser, setRiskUser] = useState();
  const [files, setFiles] = useState([]);
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleFileSelect2 = (event) => {
    setSelectedFile2(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  const handleFileChange = (event) => {
    const newFiles = [...event.target.files];
    setFiles([...files, ...newFiles]);
  };

  const handleInput = (e) => {
    e.persist();
    setUser({ ...userCreate, [e.target.name]: e.target.value });
    if (e.target.name === "userCitizenship") {
      console.log(e);
      dataFilter(e);
    }
    if (e.target.name === "userInn") {
      if (e.target.value.length > 14) {
        e.target.value = e.target.value.slice(0, 14);
        setUser({ ...userCreate, [e.target.name]: e.target.value });
      }
    }
    if (e.target.name === "userCitizenship") {
      const selectedCountry = country.find(
        (item) => item.id === Number(e.target.value)
      );
      setRiskUser(selectedCountry.sanction);
    }
  };
  const handlerCheckbox = (e) => {
    if (e.target.checked) {
      setisVerified(1);
    } else {
      setisVerified(0);
    }
  };

  function dataFilter(event) {
    const iValue = event.target.value;
    if (Number(iValue) === 1) {
      setIsKgz("d-none");
      setOtherland("");
    } else {
      setIsKgz("");
      setOtherland("d-none");
    }
  }

  const submitUser = (e) => {
    setValidated(true);
    e.preventDefault();
    let fullDateOfBirth = String(userCreate.userInn).slice(1, -5);
    let dayOf = fullDateOfBirth.slice(0, 2);
    let monthOf = fullDateOfBirth.slice(2, -4);
    let yearOf = fullDateOfBirth.slice(4);
    let dbFromInn = dayOf + "/" + monthOf + "/" + yearOf;

    const dataForBl = {
      last_name: userCreate.userSurname,
      first_name: userCreate.userName,
      middle_name: userCreate.middleName,
      country_id: userCreate.userCitizenship,
      birth_date:
        isKgz === "d-none"
          ? dbFromInn !== "//"
            ? dbFromInn
            : ""
          : moment(userCreate.birthDate).format("DD/MM/YYYY"),
    };
    axios.post("/added-users/search", dataForBl).then((res) => {
      res.data.forEach((item) => {
        if (
          item.first_name === dataForBl.first_name &&
          item.last_name === dataForBl.last_name &&
          item.middle_name === dataForBl.middle_name &&
          item.birth_date === dataForBl.birth_date
        ) {
          if (item.black_list === false) {
          } else {
            setisInblList(true);
            switch (item.type) {
              case "forall":
                swal(
                  "Ошибка",
                  "Данный клиент находится в списке : Сводный санкционный перечень Кыргызской Республики",
                  "error"
                );
                break;
              case "pft":
                swal(
                  "Ошибка",
                  "Данный клиент находится в списке : Перечень физических лиц...(ПФТ)",
                  "error"
                );
                break;
              case "plpd":
                swal(
                  "Ошибка",
                  "Данный клиент находится в списке : Перечень лиц, групп, организаций...(ПЛПД)",
                  "error"
                );
                break;
              case "un":
                swal(
                  "Ошибка",
                  "Данный клиент находится в списке : Сводный санкционный перечень Совета Безопасности ООН",
                  "error"
                );
                break;
            }
          }
        } else {
          setisInblList(false);
        }
      });
    });
    const formData = new FormData();

    if (isInblList === false) {
      formData.append("last_name", userCreate.userSurname);
      formData.append("first_name", userCreate.userName);
      formData.append("middle_name", userCreate.middleName);
      formData.append(
        "birth_date",
        isKgz === "d-none"
          ? dbFromInn !== "//"
            ? dbFromInn
            : ""
          : moment(userCreate.dateOfBirth).format("DD/MM/YYYY")
      );
      formData.append("country_id", userCreate.userCitizenship);
      formData.append("verification", isVerified);
      formData.append(
        "verification_date",
        moment(userCreate.verificationDate).format("DD/MM/YYYY")
      );
      if (userCreate.userCitizenship === "1") {
        formData.append(
          "pass_num_inn",
          userCreate.userInn === "" ||
            userCreate.userInn === null ||
            userCreate.userInn.length === 0
            ? "00000000" + userCreate.passport_id
            : userCreate.userInn
        );
      }
      formData.append("passport_photo[]", passport_photo);
      formData.append("passport_photo[]", passport_photo2);
      files.map((item) => {
        formData.append("cv_photo[]", item);
      });
      if (userCreate.userCitizenship !== "1") {
        formData.append(
          "passport_id",
          userCreate.passport_id === "" ||
            userCreate.passport_id === null ||
            userCreate.passport_id.length === 0
            ? "0000"
            : userCreate.passport_id
        );
        formData.append(
          "passport_authority",
          userCreate.passport_authority === "" ||
            userCreate.passport_authority === null ||
            userCreate.passport_authority.length === 0
            ? "0000"
            : userCreate.passport_authority
        );
        formData.append(
          "passport_authority_code",
          userCreate.passport_authority_code === "" ||
            userCreate.passport_authority_code === null ||
            userCreate.passport_authority_code === 0
            ? "0000"
            : userCreate.passport_authority_code
        );
        formData.append(
          "passport_issued_at",
          userCreate.passport_issued_at === "" ||
            userCreate.passport_issued_at === null ||
            userCreate.passport_issued_at.length === 0
            ? "15/09/1987"
            : moment(userCreate.passport_issued_at).format("DD/MM/YYYY")
        );
        formData.append(
          "passport_expires_at",
          userCreate.passport_expires_at === "" ||
            userCreate.passport_expires_at === null ||
            userCreate.passport_expires_at.length === 0
            ? "15/09/1987"
            : moment(userCreate.passport_expires_at).format("DD/MM/YYYY")
        );
      }
      for (let key of formData.entries()) {
        console.log(key[0] + ", " + key[1]);
      }
      axios
        .post("/added-users", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status === 201) {
            swal("Успешно", "Вы создали клиента", "success");
          } else {
            swal("Ошибка", "При создание клиента произошла ошибка", "error");
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            swal("Ошибка", "При создание клиента произошла ошибка", "error");
          }
        });
    }
  };
  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={submitUser}
        id="CREATEUSERFORM"
      >
        <Row className="mb-3">
          <Form.Group
            as={Col}
            md="4"
            className="mb-3 "
            controlId="validationCustom03"
          >
            <Form.Label>Фамилия*</Form.Label>
            <Form.Control
              type="text"
              value={userCreate.userSurname}
              onChange={handleInput}
              name="userSurname"
              required
            />
            <Form.Control.Feedback type="invalid">
              Заполните поле Фамилия*
            </Form.Control.Feedback>
            <Form.Control.Feedback>
              Поле успешно заполнено
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mb-3 "
            controlId="validationCustom04"
          >
            <Form.Label>Имя*</Form.Label>
            <Form.Control
              name="userName"
              className="form-control"
              value={userCreate.userName}
              onChange={handleInput}
              required
            />
            <Form.Control.Feedback type="invalid">
              Заполните поле Имя*
            </Form.Control.Feedback>
            <Form.Control.Feedback>
              Поле успешно заполнено
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mb-3 "
            controlId="validationCustom05"
          >
            <Form.Label>Отчество</Form.Label>
            <Form.Control
              type="text"
              name="middleName"
              className="form-control"
              value={userCreate.middleName}
              onChange={handleInput}
            />
            <Form.Control.Feedback type="invalid">
              Заполните поле Отчество*
            </Form.Control.Feedback>
            <Form.Control.Feedback>
              Поле успешно заполнено
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mb-3 "
            controlId="validationCustom07"
          >
            <Form.Label className="d-flex">
              Гражданство*
              {userCreate.userCitizenship === "" ? (
                ""
              ) : riskUser === 1 || riskUser === 2 ? (
                <div className="text-red ">&#160; Рискованные</div>
              ) : (
                ""
              )}
            </Form.Label>
            <Form.Select
              name="userCitizenship"
              value={userCreate.userCitizenship}
              onChange={handleInput}
            >
              <option value="">Выберите страну</option>
              {country &&
                country.length !== 0 &&
                country.map((item) => {
                  return (
                    <option
                      key={item.id}
                      value={item.id}
                      onChange={() => setRiskUser(item.sanction)}
                    >
                      {item.name}
                    </option>
                  );
                })}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Заполните поле Гражданство*
            </Form.Control.Feedback>
            <Form.Control.Feedback>
              Поле успешно заполнено
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className={`${isKgz} mb-3`}
            controlId="validationCustom06"
          >
            <Form.Label>Дата рождения*</Form.Label>
            <Form.Control
              type="date"
              name="dateOfBirth"
              className="form-control"
              value={
                isKgz === "d-none"
                  ? (userCreate.dateOfBirth = "")
                  : userCreate.dateOfBirth
              }
              onChange={handleInput}
              placeholder="13.09.1976"
              required
            />
            <Form.Control.Feedback type="invalid">
              Заполните поле Дата рождения*
            </Form.Control.Feedback>
            <Form.Control.Feedback>
              Поле успешно заполнено
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className={`${isOtherLand} mb-3`}
            controlId="validationCustom08"
          >
            <Form.Label>ИНН*</Form.Label>
            <Form.Control
              type="number"
              pattern="[0-9]*"
              name="userInn"
              className="form-control"
              value={
                isKgz !== "d-none"
                  ? (userCreate.userInn = "")
                  : userCreate.userInn
              }
              onChange={handleInput}
            />
            <Form.Control.Feedback type="invalid">
              Заполните поле ИНН*
            </Form.Control.Feedback>
            <Form.Control.Feedback>
              Поле успешно заполнено
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <hr />
        <Row className="mb-3">
          <Form.Group
            as={Col}
            md="4"
            className={`${isKgz} mb-3`}
            controlId="validationCustom08"
          >
            <Form.Label>Паспорт (Серия номер) *</Form.Label>
            <Form.Control
              type="text"
              name="passport_id"
              className="form-control"
              value={
                isKgz === "d-none"
                  ? (userCreate.passport_id = "")
                  : userCreate.passport_id
              }
              onChange={handleInput}
              required
            />
            <Form.Control.Feedback type="invalid">
              Заполните поле Паспорт
            </Form.Control.Feedback>
            <Form.Control.Feedback>
              Поле успешно заполнено
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className={`${isKgz} mb-3`}
            controlId="validationCustom08"
          >
            <Form.Label>Кто выдал *</Form.Label>
            <Form.Control
              type="text"
              name="passport_authority"
              className="form-control"
              value={
                isKgz === "d-none"
                  ? (userCreate.passport_authority = "")
                  : userCreate.passport_authority
              }
              onChange={handleInput}
              required
            />
            <Form.Control.Feedback type="invalid">
              Заполните поле Кто выдал
            </Form.Control.Feedback>
            <Form.Control.Feedback>
              Поле успешно заполнено
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className={`${isKgz} mb-3`}
            controlId="validationCustom08"
          >
            <Form.Label>Код-подразделения</Form.Label>
            <Form.Control
              type="text"
              name="passport_authority_code"
              className="form-control"
              value={
                isKgz === "d-none"
                  ? (userCreate.passport_authority_code = "")
                  : userCreate.passport_authority_code
              }
              onChange={handleInput}
            />
            <Form.Control.Feedback type="invalid">
              Заполните поле Код-подразделения
            </Form.Control.Feedback>
            <Form.Control.Feedback>
              Поле успешно заполнено
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className={`${isKgz} mb-3`}
            controlId="validationCustom08"
          >
            <Form.Label>Дата выдачи *</Form.Label>
            <Form.Control
              type="date"
              name="passport_issued_at"
              className="form-control"
              value={
                isKgz === "d-none"
                  ? (userCreate.passport_issued_at = "")
                  : userCreate.passport_issued_at
              }
              onChange={handleInput}
              required
            />
            <Form.Control.Feedback type="invalid">
              Заполните поле Дата выдачи
            </Form.Control.Feedback>
            <Form.Control.Feedback>
              Поле успешно заполнено
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className={`${isKgz} mb-3`}
            controlId="validationCustom08"
          >
            <Form.Label>Дата окончания</Form.Label>
            <Form.Control
              type="date"
              name="passport_expires_at"
              className="form-control"
              value={
                isKgz === "d-none"
                  ? (userCreate.passport_expires_at = "")
                  : userCreate.passport_expires_at
              }
              onChange={handleInput}
            />
            <Form.Control.Feedback type="invalid">
              Заполните поле Дата окончания
            </Form.Control.Feedback>
            <Form.Control.Feedback>
              Поле успешно заполнено
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className={`${createUserFormStyles.imageUpload} mb-3`}
            controlId="validationCustom06"
          >
            <Form.Label>Фото паспорта*</Form.Label>
            <Form.Control
              type={"file"}
              onChange={handleFileSelect}
              name="passport_photo"
              required
            />
            <Form.Control.Feedback type="invalid">
              Заполните поле Дата первичной верификации
            </Form.Control.Feedback>
            <Form.Control.Feedback>
              Поле успешно заполнено
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className={`${createUserFormStyles.imageUpload}  ${
              isKgz === "d-none" ? "" : "d-none"
            } mb-3`}
            controlId="validationCustom06"
          >
            <Form.Label>Фото паспорта</Form.Label>
            <Form.Control
              type={"file"}
              onChange={handleFileSelect2}
              name="passport_photo2"
            />
            <Form.Control.Feedback type="invalid">
              Заполните поле Дата первичной верификации
            </Form.Control.Feedback>
            <Form.Control.Feedback>
              Поле успешно заполнено
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <hr />

        <Row className="mb-3">
          <Form.Group
            as={Col}
            md="4"
            className={`${createUserFormStyles.imageUpload} mb-3`}
            controlId="validationCustom06"
          >
            <Form.Label>Скан анкеты*</Form.Label>
            <Form.Control
              type={"file"}
              onChange={handleFileChange}
              name="cv_photo"
              required
            />
            <Form.Control.Feedback type="invalid">
              Заполните поле Дата первичной верификации
            </Form.Control.Feedback>
            <Form.Control.Feedback>
              Поле успешно заполнено
            </Form.Control.Feedback>
          </Form.Group>

          {/* Дата первичной верификации */}
          <Form.Group
            as={Col}
            md="4"
            className={`mb-3`}
            controlId="validationCustom06"
          >
            <Form.Label>Дата первичной верификации*</Form.Label>
            <Form.Control
              type="date"
              name="verificationDate"
              className="form-control"
              value={userCreate.verificationDate}
              onChange={handleInput}
              placeholder="13.09.1976"
              required
            />
            <Form.Control.Feedback type="invalid">
              Заполните поле Дата первичной верификации
            </Form.Control.Feedback>
            <Form.Control.Feedback>
              Поле успешно заполнено
            </Form.Control.Feedback>
          </Form.Group>
          {/*  */}

          {files.map((file, index) => (
            <input key={index} type="file" onChange={handleFileChange} />
          ))}
          {/* Верификация */}
          <Form.Group
            as={Col}
            md="3"
            className={`mb-3 d-flex flex-column align-content-center justify-content-center`}
            controlId="validationCustom08"
          >
            <Form.Label>Верификация*</Form.Label>
            <Form.Check
              inline
              name={`verification`}
              type={"checkbox"}
              className={`${createUserFormStyles.checkbox}`}
              onChange={handlerCheckbox}
            />
            <Form.Control.Feedback type="invalid">
              Заполните поле Дата окончания
            </Form.Control.Feedback>
            <Form.Control.Feedback>
              Поле успешно заполнено
            </Form.Control.Feedback>
          </Form.Group>
          {/*  */}
        </Row>

        <Button type="submit" className="mt-1">
          Создать
        </Button>
      </Form>
    </>
  );
}

export default UserCreateForm;
