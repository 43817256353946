import axios from "axios";
import {useEffect, useState} from "react";

import swal from "sweetalert";
import UserModal from "../../../../user-modal/user-modal";
import moment from "moment";
import {useSelector} from "react-redux";
import LegalModal from "../../../../legal-modal/legal-modal";
import ReactPaginate from "react-paginate";

function UserListTable(
    {
        update,
        userList,
        userListFun,
        country,
    }
) {
    const [role, setRole] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [userData, setUserData] = useState();
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1)

    // Определяет выбор юридического или физического лица
    const toggleValue = useSelector((state) => state.toggle);
    //

    useEffect(() => {
        if (toggleValue) {
            axios.get("/legal-entities").then((res) => {
                userListFun(res.data);
                setPageCount(res.data);
            });
        } else {
            if (userList[1] !== 'noPagination'){
                axios.get(`/added-users?page=${currentPage}`).then((res) => {
                    userListFun(res.data);
                    setPageCount(res.data[1].totalPages);
                });
            }

        }
    }, [update, toggleValue, modalShow]);

    const deleteUser = (e, id) => {
        e.preventDefault();

        const thisClicked = e.currentTarget;

        swal({
            title: "Вы уверены что хотите удалить клиента?",
            icon: "warning",
            buttons: ["Отменить", "Да"],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(`/added-users/${id}`).then((res) => {
                    if (res.status === 204) {
                        swal("Успешно", "Вы удалили клиента", "success");
                        thisClicked.closest("tr").remove();
                    }
                });
            }
        });
    };
    const showPopUp = (e, id) => {
        e.preventDefault();
        axios.get(`/added-users/${id}`).then((res) => {
            if (res.status === 200) {
                setUserData(res.data);
                setModalShow(true);
            }
        });
    };
    useEffect(() => {
        axios.get("/auth/me").then((res) => {
            if (res.data.user === "Admin") {
                setRole(true);
            } else {
                setRole(false);
            }
        });
    }, []);

    const handlePageChange = (selectedObject) => {
        setCurrentPage(selectedObject.selected + 1)
        console.log(selectedObject.selected + 1)
        axios
            .get("/added-users", {
                params: {
                    page: selectedObject.selected + 1,
                },
            })
            .then((res) => {
                userListFun(res.data);
            });
    };

    return (
        <section
            className={`history-data-table`}
            style={userList[1] !== 'noPagination' ? {}: {maxHeight:'500px'}}
        >
            {toggleValue ? (
                <LegalModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    userdata={userData}
                    country={country}
                />
            ) : (
                <UserModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    userdata={userData}
                    country={country}
                />
            )}

            <table className="table table-striped table-hover table-history">
                <tbody id="allClientsInfo">
                <tr>
                    <th> ID</th>
                    <th> Фамилия</th>
                    <th> Имя</th>
                    <th> Отчество</th>
                    <th> Дата рождения</th>
                    <th> Дата регистрации</th>
                    <th> Рискованные</th>
                    <th></th>
                    <th></th>
                </tr>

                {userList[0] && Array.isArray(userList[0]) ?
                    userList[0].map((item, index) => {
                        return (
                            <tr className="client-data" key={item.id}>
                                <td>{item.id}</td>
                                <td>
                                    {item.last_name && item.last_name !== "undefined"
                                        ? item.last_name
                                        : ""}
                                </td>
                                <td>
                                    {item.first_name && item.first_name !== "undefined"
                                        ? item.first_name
                                        : ""}
                                </td>
                                <td>
                                    {item.middle_name && item.middle_name !== "undefined"
                                        ? item.middle_name
                                        : ""}
                                </td>
                                <td>
                                    {moment(item.birth_date, "DD.MM.YYYY").format("DD.MM.YYYY")}
                                </td>
                                <td>
                                    {moment(item.registration_date, "DD.MM.YYYY").format(
                                        "DD.MM.YYYY HH:MM"
                                    )}
                                </td>
                                <td>
                                    {item.sanction !== 0 ? (
                                        <span className="text-red">Да</span>
                                    ) : (
                                        <span className="text-green">Нет</span>
                                    )}
                                </td>
                                <td className={"d-flex justify-content-center"}>
                                    <button
                                        type="button"
                                        onClick={(e) => showPopUp(e, item.id)}
                                        className="show_user_cart_button"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-eye"
                                        >
                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                            <circle cx="12" cy="12" r="3"></circle>
                                        </svg>
                                    </button>
                                </td>
                                <td>
                                    {role ? (
                                        <button
                                            type="button"
                                            onClick={(e) => deleteUser(e, item.id)}
                                            className="delete__button"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="feather feather-trash-2"
                                            >
                                                <polyline points="3 6 5 6 21 6"></polyline>
                                                <path
                                                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                                <line x1="14" y1="11" x2="14" y2="17"></line>
                                            </svg>
                                        </button>
                                    ) : (
                                        ""
                                    )}
                                </td>
                            </tr>
                        );
                    }) : userList[0] &&

                    <tr className="client-data" key={userList[0].id}>
                        <td>{userList[0].id}</td>
                        <td>
                            {userList[0].last_name && userList[0].last_name !== "undefined"
                                ? userList[0].last_name
                                : ""}
                        </td>
                        <td>
                            {userList[0].first_name && userList[0].first_name !== "undefined"
                                ? userList[0].first_name
                                : ""}
                        </td>
                        <td>
                            {userList[0].middle_name && userList[0].middle_name !== "undefined"
                                ? userList[0].middle_name
                                : ""}
                        </td>
                        <td>
                            {moment(userList[0].birth_date, "DD.MM.YYYY").format("DD.MM.YYYY")}
                        </td>
                        <td>
                            {moment(userList[0].registration_date, "DD.MM.YYYY").format(
                                "DD.MM.YYYY HH:MM"
                            )}
                        </td>
                        <td>
                            {userList[0].sanction !== 0 ? (
                                <span className="text-red">Да</span>
                            ) : (
                                <span className="text-green">Нет</span>
                            )}
                        </td>
                        <td className={"d-flex justify-content-center"}>
                            <button
                                type="button"
                                onClick={(e) => showPopUp(e, userList[0].id)}
                                className="show_user_cart_button"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-eye"
                                >
                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                    <circle cx="12" cy="12" r="3"></circle>
                                </svg>
                            </button>
                        </td>
                        <td>
                            {role ? (
                                <button
                                    type="button"
                                    onClick={(e) => deleteUser(e, userList[0].id)}
                                    className="delete__button"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-trash-2"
                                    >
                                        <polyline points="3 6 5 6 21 6"></polyline>
                                        <path
                                            d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                        <line x1="10" y1="11" x2="10" y2="17"></line>
                                        <line x1="14" y1="11" x2="14" y2="17"></line>
                                    </svg>
                                </button>
                            ) : (
                                ""
                            )}
                        </td>
                    </tr>
                }
                </tbody>
            </table>

            {userList[1] !== 'noPagination' ? <ReactPaginate
                    pageCount={pageCount}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination ml-1"}
                    previousLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    nextLabel={`>`}
                    previousLabel={`<`}
                    pageClassName={"page-item"}
                    disabledClassNae={"disabled"}
                    activeClassName={"active"}
                />
                : ''

            }

        </section>
    );
}

export default UserListTable;
