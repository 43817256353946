import React from "react";
import modalStyles from "./user-cart-modal.module.css";
import Form from "react-bootstrap/Form";

function Verification({ editOn, setEdit, editInfo, userdata }) {
  return (
    <>
      {/* Верификация  */}
      <li className={`${modalStyles.user_info_item}`}>
        <b>Верифицирован : </b>
        {editOn ? (
          <Form.Select
            name="verification"
            onChange={(e) => {
              setEdit({
                ...editInfo,
                verification: parseInt(e.target.value),
              });
            }}
            className="w-50"
            defaultValue={userdata.verification}
          >
            <option selected={userdata.verification === 1} value={1}>
              Да
            </option>
            <option selected={userdata.verification === 0} value={0}>
              Нет
            </option>
          </Form.Select>
        ) : (
          <span
            className={`${modalStyles.user__editinput}  ${
              userdata.verification === 1 ? "text-green" : "text-danger"
            }`}
          >
            {userdata.verification === 1 ? "Да" : "Нет"}
          </span>
        )}
      </li>
      {/*  */}
      {/* Дата первичной верификации */}
      <li className={`${modalStyles.user_info_item}`}>
        <b>Дата первичной верификации : </b>
        <span
          contentEditable={editOn ? "true" : "false"}
          onInput={(e) => {
            setEdit({ ...editInfo, verification_date: e.target.innerText });
          }}
          className={
            editOn ? "form-control w-auto" : modalStyles.user__editinput
          }
        >
          {userdata.verification_date}
        </span>
      </li>
      {/*  */}
      {/* Дата верификации */}
      <li className={`${modalStyles.user_info_item}`}>
        <b>Дата верификации : </b>
        <span
          contentEditable={editOn ? "true" : "false"}
          onInput={(e) => {
            setEdit({ ...editInfo, verification_date: e.target.innerText });
          }}
          className={
            editOn ? "form-control w-auto" : modalStyles.user__editinput
          }
        >
          {userdata.verification_date}
        </span>
      </li>
      {/*  */}

      <div className="row">
        {userdata.attachments.map((item, index) => {
          console.log(item.type);
          if (item.type === "cv") {
            return (
              <div className="col-lg-4 text-center" key={index}>
                <div className="user__cart--block">
                  <img
                    className={"user_cart_img"}
                    src={`https://${item.url && item.url}`}
                  />
                  <div className="page-file__option">
                    <button type="button" className="page-file__zoom"></button>
                    <button
                      type="button"
                      className="page-file__delete"
                    ></button>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    </>
  );
}

export default Verification;
