import React from "react";
import modalStyles from "./user-cart-modal.module.css";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { countryData } from "../../../store/country/country-slice";
import "photoswipe/dist/photoswipe.css";

import { Gallery, Item } from "react-photoswipe-gallery";
import pdf_icon from "../../../images/pdf_icon.svg";

function LegalDirector({ editOn, setEdit, editInfo, userdata }) {
  const dispatch = useDispatch();
  const { country } = useSelector((state) => state.country);

  useEffect(() => {
    dispatch(countryData());
  }, [editOn]);

  return (
    <>
      <ul className="p-0">
        {/* ФИО ДИРЕКТОРА, */}
        <li className={`${modalStyles.user_info_item}`}>
          <b>ФИО директора : </b>
          <span
            className={
              editOn ? "form-control w-auto" : modalStyles.user__editinput
            }
            contentEditable={editOn ? "true" : "false"}
            onInput={(e) => {
              setEdit({ ...editInfo, director_full_name: e.target.innerText });
            }}
          >
            {userdata.director_full_name}
          </span>
        </li>
        {/*  */}

        {/* Дата рождение директора */}
        <li className={`${modalStyles.user_info_item}`}>
          <b>Дата рождение директора:</b>
          <span
            className={
              editOn ? "form-control w-auto" : modalStyles.user__editinput
            }
            contentEditable={editOn ? "true" : "false"}
            onInput={(e) => {
              setEdit({ ...editInfo, birth_date: e.target.innerText });
            }}
          >
            {userdata.birth_date}
          </span>
        </li>
        {/*  */}
      </ul>

      {/* Фото паспорта */}
      <div className="row">
        <Gallery>
          {userdata.attachments.map((attachment, index) => {
            const parts = attachment?.url.split('/');
            const lastPart = parts[parts.length - 1];
            const fileFormat = lastPart.substring(lastPart.lastIndexOf('.'));
            if (attachment.type === "passport_photo" && fileFormat !== ".pdf") {
              return (
                  <Item
                      original={`https://${attachment.url}`}
                      thumbnail={`https://${attachment.url}`}
                      width="1024"
                      height="768"
                  >
                    {({ ref, open }) => (
                        <div className="col-lg-4 text-center" key={index}>
                          <div className="user__cart--block">
                            <img
                                className={"user_cart_img"}
                                src={`https://${attachment.url}`}
                            />
                            <div className="page-file__option">
                              <button
                                  type="button"
                                  className="page-file__zoom"
                                  onClick={open}
                              ></button>
                              <button
                                  type="button"
                                  className="page-file__delete"
                              ></button>
                            </div>
                          </div>
                        </div>
                    )}
                  </Item>
              );
            }
            else if (attachment.type === "passport_photo" && fileFormat === '.pdf') {
              return (
                  <div className="col-lg-4 text-center" >
                    <div  className="user__cart--block link_to_pdf">
                      <img
                          className={"user_cart_img "}
                          src={pdf_icon}
                      />
                      <div className="page-file__option">
                        <a
                            target={'_blank'}
                            href={`https://${attachment?.url}`}
                            type="button"
                            className="page-file__zoom pdf"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 20h16m-8-6V4m0 10l4-4m-4 4l-4-4"/></svg>
                        </a>
                        <button
                            type="button"
                            className="page-file__delete"
                        ></button>
                      </div>
                    </div>
                  </div>
              )
            }
          })}
        </Gallery>
      </div>
      {/*  */}
    </>
  );
}

export default LegalDirector;
