import axios from "axios";
import logo from "../../images/logo.svg";
import style from "./app-header.module.css";
import { useNavigate } from "react-router-dom";
import { setToggle } from "../../store/legal-individual/legal-individual-slice";
import { useDispatch, useSelector } from "react-redux";
import {useEffect, useState} from "react";

function AppHeader({ title }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentUser, setCurrentUser] = useState('')
  const logout = (e) => {
    e.preventDefault();
    axios.get(`/auth/logout`).then((res) => {
      console.log(res);
      localStorage.clear();
      if (res.status === 204) {
        navigate("/login");
      }
    });
  };
  useEffect(()=>{
    axios.get('/auth/me').then((res) =>{
      setCurrentUser(res.data.email)
    })
  })

  const toggleValue = useSelector((state) => state.toggle);

  const handleToggle = () => {
    const newToggleValue = !toggleValue;
    dispatch(setToggle(newToggleValue));
  };

  return (
    <>
      <nav className="navbar navbar-expand justify-content-between fixed-top pl-3 pr-3">
        <img src={logo} className={style.logo} />
        <div className="d-flex  fiz-face">
          <span>Физ.лицо</span>
          <label className="switch">
            <input
              type="checkbox"
              checked={toggleValue}
              onChange={handleToggle}
            />
            <span className="slider"></span>
          </label>
          <span>Юр.лицо</span>
        </div>
        <div className={style.logout} onClick={logout}>
          Выход
        </div>
        <div className={'current_user_email'}>
          {currentUser}
        </div>
      </nav>
    </>
  );
}

export default AppHeader;
