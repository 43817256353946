import axios from "axios";
import swal from "sweetalert";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../images/logo.svg";

const Login = () => {
  const navigate = useNavigate();
  const [loginInput, setLogin] = useState({
    email: "",
    password: "",
    error_list: [],
  });
  const handleInput = (e) => {
    e.persist();

    setLogin({
      ...loginInput,
      [e.target.name]: e.target.value,
    });
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: loginInput.email,
      password: loginInput.password,
    };

    axios.get("https://lk2.bitok.kg/sanctum/csrf-cookie").then((response) => {
      axios.post("/auth/login", data).then((res) => {
        if (res.status === 200) {
          localStorage.setItem("bitok_auth_token", res.data.access_token);
          swal("Успешно", "Вы успешно авторизовались", "success");
          navigate("/");
        } else if (res.status === 401) {
          swal("Внимание", "Произошла ошибка попробуйте еще.", "warning");
        } else {
          setLogin({
            ...loginInput,
            error_list: "Invalid login details",
          });
        }
      });
    });
  };

  const errorEmailActive = loginInput.error_list.email ? "has-error" : "";
  const errorPasswordActive = loginInput.error_list.password ? "has-error" : "";

  return (
    <div className="containter">
      <div className="row justify-content-center mt-5">
        <div className="col-lg-5">
          <form onSubmit={loginSubmit}>
            <div className="card mb-grid">
              <div className="card-header">
                <div className="card-header-title">Авторизация</div>
              </div>
              <div className="card-body w-100">
                <div className="d-flex justify-content-center">
                  <img src={logo} alt="Логотип" width="60px" />
                </div>
                <div className={`form-group ${errorEmailActive}`}>
                  <div className="form-group">
                    <label className="form-label">Email </label>
                    <input
                      type="email"
                      name="email"
                      onChange={handleInput}
                      value={loginInput.email}
                      className="form-control"
                      placeholder="Введите email"
                    />
                    <span>{loginInput.error_list.email}</span>
                  </div>
                </div>

                <div className={`form-group ${errorPasswordActive}`}>
                  <label className="form-label">Пароль</label>
                  <input
                    type="password"
                    name="password"
                    onChange={handleInput}
                    value={loginInput.password}
                    className="form-control"
                    placeholder="Введите пароль"
                  />
                  <span>{loginInput.error_list.password}</span>
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-primary mt-2">
                    Войти
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
