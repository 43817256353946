import axios from "axios";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import swal from "sweetalert";

function QarCodeModal(props) {
  const [walletValue, setWalletValue] = useState("");
  const funWallet = (e) => {
    setWalletValue(e.target.value);
  };
  const changeWalletId = (e) => {
    e.preventDefault();
    props.setQrUpdateList(Date.now);
    axios
      .put(`/user-operations/${props.user.operation_id}`, {
        wallet_id: walletValue,
      })
      .then((res) => {
        if (res.status === 200) {
          swal(`Номер кошелька сохранен!`, {
            icon: "success",
          });
        }
      });
  };
  return props.qrData ? (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {"Картинка qr кода"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.qrData.attachments !== undefined
          ? props.qrData.attachments.map((el) => {
              console.log(el);
              return (
                <img
                  className={"qr_code_img"}
                  src={`https://${el.url}`}
                  key={el.id}
                />
              );
            })
          : ""}
      </Modal.Body>
      <Modal.Footer>
        <Form noValidate onSubmit={changeWalletId} className="d-flex w-100">
          <div className="col-lg-8">
            <Form.Control
              type="text"
              name="wallet_id"
              className="form-control"
              onChange={funWallet}
              defaultValue={props.user.wallet_id}
            />
          </div>
          <div className="col-lg-3">
            <Button type="submit">Сохранить</Button>
          </div>
        </Form>
      </Modal.Footer>
    </Modal>
  ) : (
    ""
  );
}
export default QarCodeModal;
