import React, { useState } from "react";
import { Modal, Button, Row, Form, Col } from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import swal from "sweetalert";
import { useNavigate } from "react-router";

function EditOperationComponent(props) {
  const [editOperation, setEditOperation] = useState({});
  const navigate = useNavigate();
  const handleInput = (e) => {
    if (e.target.name === "operation_date") {
      setEditOperation({
        ...editOperation,
        [e.target.name]: moment(e.target.value, "YYYY-MM-DDTHH:mm").format(
            "DD/MM/YYYY HH:mm"
        ),
      });
    } else {
      setEditOperation({
        ...editOperation,
        [e.target.name]: e.target.value,
      });
    }
  };
  console.log(props);
  const submitEditOperation = (e) => {
    e.preventDefault();
    axios
      .put(
        `/api/user-operations/${props.operation.operation_id}`,
        editOperation
      )
      .then((res) => {
        if (res.status === 200) {
          swal("Успешно", "Редактирование произошло успешно", "success");
          props.setUpdateEdit(new Date()); //Обновляем список операций
          props.onHide(true);
        } else {
          swal("Ошибка", "Произошла ошибка", "error");
        }
      });
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Редактирование
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3">
          <Form onSubmit={submitEditOperation}>
            <Row className="mb-3 align-items-start">
              {/* Дата */}
              <Form.Group
                as={Col}
                md="12"
                className="mb-3 "
                controlId="validationCustom03"
              >
                <Form.Label>Дата</Form.Label>
                <Form.Control
                  type="datetime-local"
                  defaultValue={moment(
                    props.operation.operation_date,
                    "DD/MM/YYYY HH:mm"
                  ).format("YYYY-MM-DDTHH:mm")}
                  onChange={handleInput}
                  name="operation_date"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Заполните поле Дата
                </Form.Control.Feedback>
                <Form.Control.Feedback>
                  Поле успешно заполнено
                </Form.Control.Feedback>
              </Form.Group>
              {/*  */}

              {/* Сумма */}
              <Form.Group
                as={Col}
                md="4"
                className="mb-3 "
                controlId="validationCustom04"
              >
                <Form.Label>Сумма</Form.Label>
                <Form.Control
                  type="text"
                  name="operation_sum"
                  className="form-control"
                  defaultValue={props.operation.operation_sum}
                  onChange={handleInput}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Заполните поле Сумма
                </Form.Control.Feedback>
                <Form.Control.Feedback>
                  Поле успешно заполнено
                </Form.Control.Feedback>
              </Form.Group>
              {/*  */}

              {/* Валюта */}
              <Form.Group
                as={Col}
                md="4"
                className="mb-3 "
                controlId="validationCustom03"
              >
                <Form.Label>Валюта</Form.Label>
                <Form.Select
                  onChange={handleInput}
                  name="currency"
                  defaultValue={props.operation.currency}
                >
                  <option disabled={true}>Не выбрано</option>
                  <option
                    selected={props.operation.direction === "som"}
                    value="som"
                  >
                    Сом
                  </option>
                  <option
                    selected={props.operation.direction === "usd"}
                    value="usd"
                  >
                    USD
                  </option>
                  <option
                    selected={props.operation.direction === "usdt"}
                    value="usdt"
                  >
                    USDT
                  </option>
                  <option
                    selected={props.operation.direction === "rub"}
                    value="rub"
                  >
                    Рубль
                  </option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Заполните поле Дата
                </Form.Control.Feedback>
                <Form.Control.Feedback>
                  Поле успешно заполнено
                </Form.Control.Feedback>
              </Form.Group>
              {/*  */}

              {/* Направление */}
              <Form.Group
                as={Col}
                md="4"
                className="mb-3"
                controlId="validationCustom03"
              >
                <Form.Label> Направление</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="operation_direction"
                  onChange={handleInput}
                >
                  <option disabled={true}>Не выбрано</option>
                  <option
                    selected={props.operation.operation_direction === "Продажа"}
                    value="Продажа"
                  >
                    Продажа
                  </option>
                  <option
                    selected={
                      props.operation.operation_direction === "Пополнение"
                    }
                    value="Пополнение"
                  >
                    Пополнение
                  </option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Заполните поле Направление
                </Form.Control.Feedback>
                <Form.Control.Feedback>
                  Поле успешно заполнено
                </Form.Control.Feedback>
              </Form.Group>
              {/*  */}
            </Row>

            <Button type="submit">Сохранить</Button>
          </Form>
        </Row>
        <br />
      </Modal.Body>
    </Modal>
  );
}

export default EditOperationComponent;
