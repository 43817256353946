import { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import createUserFormStyles from "./create-legal-form.module.css";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { countryData } from "../../../../store/country/country-slice";
import { legalCreate } from "../../../../store/legal/legal-create/legal-create-slice";
import swal from "sweetalert";
import moment from "moment";
import {Switch} from "@mui/material";
import CreateLegalStock from "./create-legal-stock";

function LegalCreateForm() {
  const dispatch = useDispatch();
  const { country } = useSelector((state) => state.country);
  useEffect(() => {
    dispatch(countryData());
  }, [dispatch]);
  const [legal, setLegal] = useState({"stock" : 0,});
  const [validated, setValidated] = useState(false);
  const [riskUser, setRiskUser] = useState();
  const [defaultCountry, setDefaultCounty] = useState();
  const [formType, setFormType] = useState(false);

  // Собираем данные  с select
  const handleCountryChange = (e) => {
    const country_id = e.target.value;
    const selectedCountry = country.find(
      (item) => item.id === Number(country_id)
    );
    handleInput(e);
    if (selectedCountry) {
      setLegal({
        ...legal,
        country_id: selectedCountry.id,
      });
      setRiskUser(selectedCountry.sanction);
    }
  };
  //

  // Собираем данные с текстовых форм
  const handleInput = (e) => {
    let { name, value } = e.target ? e.target : e;
    if (name === "birth_date") {
      value = moment(value).format("DD/MM/YYYY");
    }
    setLegal({
      ...legal,
      [name]: value,
    });
  };
  //
  //переключаемся на биржу и обратно
  // switch to stock and back
  const handleFormChange = ()=>{
    setFormType(!formType);
  }

  // Cобираем файловые данные
  const handleFileInput = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      setLegal((prevState) => ({
        ...prevState,
        [name]: [files[0]], // здесь мы сохраняем объект File, а не строку
      }));
    }
  };
  //

  const submitLegal = (e) => {
    e.preventDefault();
    dispatch(legalCreate(legal)).then((response) => {
      if (response.payload === 201) {
        swal("Пользователь успешно создан!", {
          icon: "success",
        });
      } else {
        let errorMessage = "Ошибка при обновлении!";

        if (response.payload.data && response.payload.data.errors) {
          const errorMessages = Object.values(response.payload.errors).reduce(
            (acc, val) => acc.concat(val),
            []
          );
          errorMessage = errorMessages.join("\n");
        }
        swal(errorMessage, {
          icon: "error",
        });
      }
    });
  };
  return (
    <>
      <span>Юр.лицо </span>
      <Switch
          defaultValue={formType}
          onChange={handleFormChange}
      />
      <span> Биржа</span>
      {!formType ?
          <Form noValidate validated={validated} onSubmit={submitLegal}>
            <Row className="mb-3">
              {/* Наименование */}
              <Form.Group
                  as={Col}
                  md="4"
                  className="mb-3 "
                  controlId="validationCustom03"
              >
                <Form.Label>Наименование*</Form.Label>
                <Form.Control
                    type="text"
                    value={legal.name}
                    onChange={handleInput}
                    name="name"
                    required
                />
                <Form.Control.Feedback type="invalid">
                  Заполните поле Фамилия*
                </Form.Control.Feedback>
                <Form.Control.Feedback>
                  Поле успешно заполнено
                </Form.Control.Feedback>
              </Form.Group>
              {/*  */}

              {/* Страна регистрации */}
              <Form.Group
                  as={Col}
                  md="4"
                  className="mb-3 "
                  controlId="validationCustom07"
              >
                <Form.Label className="d-flex">
                  Страна регистрации*
                  {legal.userCitizenship === "" ? (
                      ""
                  ) : riskUser === 1 || riskUser === 2 ? (
                      <div className="text-red ">&#160; Рискованные</div>
                  ) : (
                      ""
                  )}
                </Form.Label>
                <Form.Select name="country_id" onChange={handleCountryChange}>
                  <option value="">Выберите страну</option>
                  {country &&
                  country.length !== 0 &&
                  country.map((item) => {
                    return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                    );
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Заполните поле Гражданство*
                </Form.Control.Feedback>
                <Form.Control.Feedback>
                  Поле успешно заполнено
                </Form.Control.Feedback>
              </Form.Group>
              {/*  */}

              {/* Адрес регистрации */}
              <Form.Group
                  as={Col}
                  md="4"
                  className="mb-3 "
                  controlId="validationCustom05"
              >
                <Form.Label>Адрес регистрации</Form.Label>
                <Form.Control
                    type="text"
                    name="address"
                    className="form-control"
                    value={legal.address}
                    onChange={handleInput}
                />
                <Form.Control.Feedback type="invalid">
                  Заполните поле Отчество*
                </Form.Control.Feedback>
                <Form.Control.Feedback>
                  Поле успешно заполнено
                </Form.Control.Feedback>
              </Form.Group>
              {/*  */}

              {/* Скан свидетельства */}
              <Form.Group
                  as={Col}
                  md="4"
                  className={`${createUserFormStyles.imageUpload} mb-3`}
                  controlId="validationCustom06"
              >
                <Form.Label>Скан свидетельства *</Form.Label>
                <Form.Control
                    type={"file"}
                    onChange={handleFileInput}
                    name="permit_photo"
                    required
                />
                <Form.Control.Feedback type="invalid">
                  Заполните поле Дата первичной верификации
                </Form.Control.Feedback>
                <Form.Control.Feedback>
                  Поле успешно заполнено
                </Form.Control.Feedback>
              </Form.Group>
              {/*  */}

              {/* Скан решения о регистрации юр. */}
              <Form.Group
                  as={Col}
                  md="4"
                  className={` mb-3`}
                  controlId="validationCustom06"
              >
                <Form.Label>Скан решения о регистрации юр.</Form.Label>
                <Form.Control
                    type={"file"}
                    onChange={handleFileInput}
                    name="certificate_photo"
                />
                <Form.Control.Feedback type="invalid">
                  Заполните поле Дата первичной верификации
                </Form.Control.Feedback>
                <Form.Control.Feedback>
                  Поле успешно заполнено
                </Form.Control.Feedback>
              </Form.Group>
              {/*  */}

              {/* Скан лицензии */}
              <Form.Group
                  as={Col}
                  md="4"
                  className={` mb-3`}
                  controlId="validationCustom06"
              >
                <Form.Label>Скан лицензии</Form.Label>
                <Form.Control
                    type={"file"}
                    onChange={handleFileInput}
                    name="licence_photo"
                />
                <Form.Control.Feedback type="invalid">
                  Заполните поле Дата первичной верификации
                </Form.Control.Feedback>
                <Form.Control.Feedback>
                  Поле успешно заполнено
                </Form.Control.Feedback>
              </Form.Group>
              {/*  */}
            </Row>
            <hr />
            <Row className="mb-3">
              {/* ФИО ДИРЕКТОРА */}
              <Form.Group
                  as={Col}
                  md="4"
                  className="mb-3 "
                  controlId="validationCustom04"
              >
                <Form.Label>ФИО директора*</Form.Label>
                <Form.Control
                    name="director_full_name"
                    className="form-control"
                    value={legal.director_full_name}
                    onChange={handleInput}
                    required
                />
                <Form.Control.Feedback type="invalid">
                  Заполните поле Имя*
                </Form.Control.Feedback>
                <Form.Control.Feedback>
                  Поле успешно заполнено
                </Form.Control.Feedback>
              </Form.Group>
              {/*  */}

              {/* ДАТА РОЖДЕНИЯ */}
              <Form.Group
                  as={Col}
                  md="4"
                  className={` mb-3`}
                  controlId="validationCustom06"
              >
                <Form.Label>Дата рождения директора*</Form.Label>
                <Form.Control
                    type="date"
                    name="birth_date"
                    className="form-control"
                    defaultValue={moment(legal.birth_date).format("YYYY-MM-DD")}
                    onChange={handleInput}
                    placeholder="13.09.1976"
                    required
                />
                <Form.Control.Feedback type="invalid">
                  Заполните поле Дата рождения*
                </Form.Control.Feedback>
                <Form.Control.Feedback>
                  Поле успешно заполнено
                </Form.Control.Feedback>
              </Form.Group>
              {/*  */}

              {/* Скан паспорта */}
              <Form.Group
                  as={Col}
                  md="4"
                  className={` mb-3`}
                  controlId="validationCustom08"
              >
                <Form.Label>Скан паспорта*</Form.Label>
                <Form.Control
                    type={"file"}
                    onChange={handleFileInput}
                    name="passport_photo"
                    required
                />
                <Form.Control.Feedback type="invalid">
                  Заполните поле Паспорт
                </Form.Control.Feedback>
                <Form.Control.Feedback>
                  Поле успешно заполнено
                </Form.Control.Feedback>
              </Form.Group>
              {/*  */}
            </Row>
            <hr />

            <Row className="mb-3">
              {/* Скан анкеты юр. лица */}
              <Form.Group
                  as={Col}
                  md="4"
                  className={` mb-3`}
                  controlId="validationCustom06"
              >
                <Form.Label>Скан анкеты юр. лица*</Form.Label>
                <Form.Control
                    type={"file"}
                    onChange={handleFileInput}
                    name="cv_photo"
                    required
                />
                <Form.Control.Feedback type="invalid">
                  Заполните поле Дата первичной верификации
                </Form.Control.Feedback>
                <Form.Control.Feedback>
                  Поле успешно заполнено
                </Form.Control.Feedback>
              </Form.Group>
              {/*  */}

              {/* Скана анкеты б.ф. (бенефициар собственника) */}
              <Form.Group
                  as={Col}
                  md="4"
                  className={` mb-3`}
                  controlId="validationCustom06"
              >
                <Form.Label>
                  Скана анкеты б.ф. (бенефициар собственника)*
                </Form.Label>
                <Form.Control
                    type={"file"}
                    onChange={handleFileInput}
                    name="cv_photo_bf"
                    required
                />
                <Form.Control.Feedback type="invalid">
                  Заполните поле Дата первичной верификации
                </Form.Control.Feedback>
                <Form.Control.Feedback>
                  Поле успешно заполнено
                </Form.Control.Feedback>
              </Form.Group>
              {/*  */}

              {/* Дата первичной верификации */}
              {/* <Form.Group
            as={Col}
            md="4"
            className={`mb-3`}
            controlId="validationCustom06"
          >
            <Form.Label>Дата первичной верификации*</Form.Label>
            <Form.Control
              type="date"
              name="verificationDate"
              className="form-control"
              value={legal.verificationDate}
              onChange={handleInput}
              placeholder="13.09.1976"
              required
            />
            <Form.Control.Feedback type="invalid">
              Заполните поле Дата первичной верификации
            </Form.Control.Feedback>
            <Form.Control.Feedback>
              Поле успешно заполнено
            </Form.Control.Feedback>
          </Form.Group> */}
              {/*  */}

              {/* Верификация */}
              {/* <Form.Group
            as={Col}
            md="3"
            className={`mb-3 d-flex flex-column align-content-center justify-content-center`}
            controlId="validationCustom08"
          >
            <Form.Label>Верификация*</Form.Label>
            <Form.Check
              inline
              name={`verification`}
              type={"checkbox"}
              className={`${createUserFormStyles.checkbox}`}
              onChange={handleInput}
            />
            <Form.Control.Feedback type="invalid">
              Заполните поле Дата окончания
            </Form.Control.Feedback>
            <Form.Control.Feedback>
              Поле успешно заполнено
            </Form.Control.Feedback>
          </Form.Group> */}
              {/*  */}
            </Row>

            <Button type="submit" className="mt-1">
              Создать
            </Button>
          </Form>
          : <CreateLegalStock />
      }

    </>
  )
}

export default LegalCreateForm;
