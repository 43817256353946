export const currency = (item) => {
  let currencyOriginal = "";
  switch (item) {
    case "som":
      currencyOriginal = "Сом";
      break;
    case "usd":
      currencyOriginal = "USD";
      break;
    case "usdt":
      currencyOriginal = "USDT";
      break;
    case "rub":
      currencyOriginal = "Рубль";
      break;
  }
  return currencyOriginal;
};
