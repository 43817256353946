import axios from "axios";

// Users All LiST
let API_URL = "/countries";

// Список USer/
const countries = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};
//
const countriesService = {
  countries,
};

export default countriesService;
