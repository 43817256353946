import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import swal from "sweetalert";

function AdminCreate() {
  const [adminCreate, setAdminCreate] = useState({
    status: 1,
  });
  const [errorAdmin, setErrorAdmin] = useState({});
  const navigate = useNavigate();

  // Собираем данные из полей
  const handleInputAdmin = (e) => {
    const { name, value, type, checked } = e.target ? e.target : e;
    let newValue = value;
    if (type === "checkbox") {
      newValue = checked ? 0 : 1;
    }
    setAdminCreate({
      ...adminCreate,
      [name]: newValue,
    });
  };
  //

  // Отправляем данные
  const submitLegal = (e) => {
    e.preventDefault();
    axios
      .post("/users", adminCreate)
      .then((res) => {
        if (res.status === 201) {
          swal("Успешно", "Вы успешно создали пользователя!", "success");
          setErrorAdmin({});
          navigate("/admins");
        }
      })
      .catch(function (error) {
        setErrorAdmin(error.response.data.error);
      });
  };

  //

  return (
    <div className="row justify-content-center">
      <div className="col-lg-6">
        <div className="card mb-grid">
          <div className="card-header d-flex justify-content-between align-items-center">
            <div className="card-header-title">Создать пользователя</div>
          </div>
          <div className="card-body collapse show">
            <form className="" onSubmit={submitLegal}>
              {/* Имя */}
              <div className="form-group">
                <label className="form-label" for="exampleInputPassword1">
                  Имя
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Введите имя"
                  name="name"
                  onChange={handleInputAdmin}
                />
              </div>
              {/*  */}

              {/* Email */}
              <div className="form-group  has-validation">
                <label className="form-label" for="exampleInputEmail1">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  className={`form-control ${errorAdmin.email && "is-invalid"}`}
                  onChange={handleInputAdmin}
                  placeholder="Введите email"
                  required
                />
                {errorAdmin.email && (
                  <div class="invalid-feedback d-block">{errorAdmin.email}</div>
                )}
              </div>
              {/*  */}

              {/* Роль */}
              <div className="form-group">
                <label className="form-label">Роль</label>

                <select
                  name="role"
                  class="form-select"
                  onChange={handleInputAdmin}
                  required
                >
                  <option value="" disabled selected>
                    Выберите роль
                  </option>
                  <option value="Admin">Admin</option>
                  <option value="Manager">Manager</option>
                  <option value="Operator">Operator</option>
                </select>
              </div>
              {/*  */}

              {/* Пароль */}
              <div className="form-group">
                <label className="form-label" for="exampleInputPassword1">
                  Пароль
                </label>
                <input
                  type="password"
                  className={`form-control ${
                    errorAdmin.password && "is-invalid"
                  }`}
                  name="password"
                  onChange={handleInputAdmin}
                  placeholder="Введите пароль"
                  required
                />
                {errorAdmin.password && (
                  <div class="invalid-feedback d-block">
                    {errorAdmin.password}
                  </div>
                )}
              </div>
              {/*  */}

              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    onChange={handleInputAdmin}
                    id="customCheck1"
                    name="status"
                  />
                  <label className="custom-control-label" for="customCheck1">
                    Не активный
                  </label>
                </div>
              </div>

              <button type="submit" className="btn btn-primary">
                Создать
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminCreate;
