import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Tabs, Tab, Button } from "react-bootstrap";

import settingsStyle from "./settings.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import SettingMenu from "./menu/menu";

function Settings({ titleChange }) {
  const [key, setKey] = useState("tab1");

  // Title страницы передаем в шапку
  useEffect(() => {
    titleChange("Настройки");
  }, [titleChange]);
  //
  const [settingsLimit, setSettingsFromBackend] = useState();
  const handleInput = (e) => {
    e.persist();
    setSettingsFromBackend({
      ...settingsLimit,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    axios
      .get(`/settings`)
      .then((res) => {
        setSettingsFromBackend(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  function submitSettings(e) {
    e.preventDefault();
    const dataSettings = {
      limit: settingsLimit.limit,
      usd_to_som: settingsLimit.usd_to_som,
      usdt_to_som: settingsLimit.usdt_to_som,
      rub_to_som: settingsLimit.rub_to_som,
      high_risk: settingsLimit.high_risk,
      risk: settingsLimit.risk,
    };
    axios.post("/settings", dataSettings).then((res) => {
      if (res.status === 200) {
        console.log(res.status);
        swal("Успешно", "Настройки успешно сохранены", "success");
      } else {
        swal("Ошибка", "При сохранении настроек произошла ошибка", "error");
        console.log(res.status);
      }
    });
  }
  return (
    settingsLimit && (
      <section className="section-border">
        <SettingMenu />
        <Form
          noValidate
          onSubmit={submitSettings}
          className={`${settingsStyle.settings_form_style} section-border`}
        >
          <Row className="mb-3">
            <Form.Group
              as={Col}
              md="12"
              className="mb-3 "
              controlId="validationCustom03"
            >
              <Form.Label>ЛИМИТ в Сомах</Form.Label>
              <Form.Control
                type="text"
                defaultValue={settingsLimit.limit}
                onChange={handleInput}
                name="limit"
                required
              />
              <Form.Control.Feedback type="invalid">
                Заполните поле Фамилия*
              </Form.Control.Feedback>
              <Form.Control.Feedback>
                Поле успешно заполнено
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Label className="mt-3">Курсы валют</Form.Label>

            <Form.Group
              as={Col}
              md="4"
              className="mb-3 "
              controlId="validationCustom03"
            >
              <Form.Label>
                <b>USD -> SOM*</b>
              </Form.Label>
              <Form.Control
                type="text"
                defaultValue={settingsLimit.usd_to_som}
                onChange={handleInput}
                name="usd_to_som"
                required
              />
              <Form.Control.Feedback type="invalid">
                Заполните поле Фамилия*
              </Form.Control.Feedback>
              <Form.Control.Feedback>
                Поле успешно заполнено
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              as={Col}
              md="4"
              className="mb-3 "
              controlId="validationCustom03"
            >
              <Form.Label>
                <b>USDT -> SOM*</b>
              </Form.Label>
              <Form.Control
                type="text"
                defaultValue={settingsLimit.usdt_to_som}
                // value={settings.usdt_to_som}
                onChange={handleInput}
                name="usdt_to_som"
                required
              />
              <Form.Control.Feedback type="invalid">
                Заполните поле Фамилия*
              </Form.Control.Feedback>
              <Form.Control.Feedback>
                Поле успешно заполнено
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              as={Col}
              md="4"
              className="mb-3 "
              controlId="validationCustom03"
            >
              <Form.Label>
                <b>RUB -> SOM*</b>
              </Form.Label>
              <Form.Control
                type="text"
                // value={settings.rub_to_som}
                defaultValue={settingsLimit.rub_to_som}
                onChange={handleInput}
                name="rub_to_som"
                required
              />
              <Form.Control.Feedback type="invalid">
                Заполните поле Фамилия*
              </Form.Control.Feedback>
              <Form.Control.Feedback>
                Поле успешно заполнено
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Label className="mt-3">Срок активации</Form.Label>
            <span className={settingsStyle.settings_description}>
              Количество дней после которых анкета становится НЕ ВЕРЕФЕЦИРОННОЙ{" "}
            </span>

            <Form.Group
              as={Col}
              md="6"
              className="mb-3 "
              controlId="validationCustom03"
            >
              <Form.Label>Обычные </Form.Label>
              <Form.Control
                type="text"
                defaultValue={settingsLimit.risk}
                onChange={handleInput}
                name="risk"
                required
              />
              <Form.Control.Feedback type="invalid">
                Заполните поле Фамилия*
              </Form.Control.Feedback>
              <Form.Control.Feedback>
                Поле успешно заполнено
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              as={Col}
              md="6"
              className="mb-3 "
              controlId="validationCustom03"
            >
              <Form.Label>Рискованные</Form.Label>
              <Form.Control
                type="text"
                defaultValue={settingsLimit.high_risk}
                onChange={handleInput}
                name="high_risk"
                required
              />
              <Form.Control.Feedback type="invalid">
                Заполните поле Фамилия*
              </Form.Control.Feedback>
              <Form.Control.Feedback>
                Поле успешно заполнено
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Button type="submit">Сохранить</Button>
        </Form>
      </section>
    )
  );
}
export default Settings;
