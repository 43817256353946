import axios from "axios";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import "moment/locale/ru";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";

function CreateOperationForm({ setUserFindValue, operation, userCheck }) {
  const toggleValue = useSelector((state) => state.toggle);

  const [userCreate, setUser] = useState({
    fio: "",
  });

  const [validated, setValidated] = useState(false);
  const numberRegex = /[0-9]/;
  const handleInput = (e) => {
    e.persist();
    setUser({ ...userCreate, [e.target.name]: e.target.value });
    if (e.target.name === "fio") {
      handleFioInputChange(e);
    }
  };

  function handleFioInputChange(event) {
    const inputValue = event.target.value;
    if (numberRegex.test(inputValue)) {
      event.target.value = inputValue.replace(numberRegex, "");
      setUser({ ...userCreate, [event.target.name]: event.target.value });
    }
  }
  const submitUser = (e) => {
    if (userCreate.fio === "") {
      e.preventDefault();
      e.stopPropagation();
    } else {
      setValidated(true);
      e.preventDefault();
      const data = {
        name: userCreate.fio,
      };
      console.log(toggleValue);
      if (toggleValue) {
        axios.post("/legal-entities/search", data).then((res) => {
          if (res.status === 200) {
            operation(res.data[0]);
          } else {
            swal("Ошибка", "При создание клиента произошла ошибка", "error");
          }
        });
      } else {
        axios.post("/added-users/search", data).then((res) => {
          console.log(res);
          if (res.status === 200) {
            operation(res.data[0]);
          } else {
            swal("Ошибка", "При создание клиента произошла ошибка", "error");
          }
        });
      }
    }
    setUserFindValue(userCreate.fio);
    userCheck("");
  };
  return (
    <>
      <Form noValidate onSubmit={submitUser} id="CREATEUSERFORM">
        <Row className="mb-3">
          <Form.Group as={Col} md="12" className="mb-3 ">
            <Form.Label>{toggleValue ? "Название компании" : "ФИО"}</Form.Label>
            <Form.Control
              type="text"
              name="fio"
              className="form-control"
              value={userCreate.fio}
              onChange={handleInput}
            />
            <Form.Control.Feedback>
              Поле успешно заполнено
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Button type="submit">Искать</Button>
      </Form>
    </>
  );
}

export default CreateOperationForm;
