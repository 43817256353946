import React from "react";

function WalletModalComponent({ wallets }) {
  return (
    <div>
      <ol>
        {wallets && wallets.length !== 0
          ? wallets.map((item, index) => {
              return item.wallet_id ? (
                <li key={index} className="mb-2">
                  <b>{item.wallet_id}</b>
                </li>
              ) : (
                <li key={index} className="mb-2">
                  <img src={`https://${item.thumbnail}`} width="50px" />
                </li>
              );
            })
          : "Нет кошелька"}
      </ol>
    </div>
  );
}

export default WalletModalComponent;
