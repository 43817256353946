import React from "react";
import { useSelector } from "react-redux";
import { Link, useMatch } from "react-router-dom";

function SettingMenu() {
  const isSettings = useMatch("/settings");
  const isAdmins = useMatch("/admins");
  const isActionsLog = useMatch("/actions-log");
  const { role } = useSelector((state) => state.role);
  console.log(role);
  return (
    <>
      <ul className="mb-3 nav nav-tabs nav-fill">
        <li className="nav-item">
          <Link
            to="/settings"
            className={`nav-link ${isSettings ? "active" : ""} `}
          >
            Настройки
          </Link>
        </li>
        {role.role === "Admin" && (
          <li className="nav-item">
            <Link
              to="/admins"
              className={`nav-link ${isAdmins ? "active" : ""} `}
            >
              Пользователи
            </Link>
          </li>
        )}

        <li className="nav-item">
          <Link
            to="/action-logs"
            className={`nav-link ${isActionsLog ? "active" : ""} `}
          >
            ЛОГИ
          </Link>
        </li>
      </ul>
    </>
  );
}

export default SettingMenu;
