import { useEffect, useState } from "react";

import axios from "axios";
import { Form } from "react-bootstrap";
import countryStyles from "./country-filter.module.css";
import swal from "sweetalert";
function CountriesFilter({ titleChange }) {
  // Title страницы передаем в шапку
  useEffect(() => {
    titleChange("Страны");
  }, [titleChange]);
  //
  const [optionsCountry, setOptionsCountry] = useState([]);
  const [dataCountry, setDataCountry] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [allCountries, setAllcountries] = useState();
  const options = [];
  const dataTemp = [];

  useEffect(() => {
    axios.get(`/countries`).then((res) => {
      let result = res.data;
      result.forEach((item, index) => {
        options.push(item);
        dataTemp.push({
          id: item.id,
          sanction: item.sanction,
        });
      });
      setOptionsCountry(options);
      setDataCountry(dataTemp);
    });
  }, []);

  function checkI() {
    let allI = document.querySelectorAll(
      "#left-tabs-example-tabpane-coutries-filter input"
    );
    allI.forEach((element) => {
      if (
        element.getAttribute("data-type") == 1 &&
        element.getAttribute("data-check") == 1
      ) {
        element.checked = true;
      } else if (
        element.getAttribute("data-type") == 2 &&
        element.getAttribute("data-check") == 2
      ) {
        element.checked = true;
      } else {
        element.checked = false;
      }
    });
  }

  const handleCheck = (event) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      const riskType = event.target.getAttribute("data-type");
      const objectToUpdate = dataCountry.find(
        (obj) => obj.id == event.target.value
      );
      if (objectToUpdate) {
        const updatedData = dataCountry.map((obj) => {
          if (obj.id == event.target.value) {
            return { ...obj, ...{ sanction: riskType } };
          }
          return obj;
        });
        setDataCountry(updatedData);
      }
      let currentCheckedId = event.target.id;
      let secondCheckedId = currentCheckedId + "_two";
      document.getElementById(secondCheckedId).setAttribute("disabled", "");
    } else {
      let currentCheckedId = event.target.id;
      let secondCheckedId = currentCheckedId + "_two";
      document.getElementById(secondCheckedId).removeAttribute("disabled", "");
      const objectToUpdate = dataCountry.find(
        (obj) => obj.id == event.target.value
      );
      if (objectToUpdate) {
        const updatedData = dataCountry.map((obj) => {
          if (obj.id == event.target.value) {
            return { ...obj, ...{ sanction: "0" } };
          }
          return obj;
        });
        setDataCountry(updatedData);
      }
    }
  };
  const handleCheck2 = (event) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      const riskType = event.target.getAttribute("data-type");
      const objectToUpdate = dataCountry.find(
        (obj) => obj.id == event.target.value
      );
      if (objectToUpdate) {
        const updatedData = dataCountry.map((obj) => {
          if (obj.id == event.target.value) {
            return { ...obj, ...{ sanction: riskType } };
          }
          return obj;
        });
        setDataCountry(updatedData);
      }

      let currentCheckedId = event.target.id;
      let secondCheckedId = currentCheckedId.substring(
        0,
        currentCheckedId.length - 4
      );
      document.getElementById(secondCheckedId).setAttribute("disabled", "");
    } else {
      let currentCheckedId = event.target.id;
      let secondCheckedId = currentCheckedId.substring(
        0,
        currentCheckedId.length - 4
      );
      document.getElementById(secondCheckedId).removeAttribute("disabled", "");

      const objectToUpdate = dataCountry.find(
        (obj) => obj.id == event.target.value
      );
      if (objectToUpdate) {
        const updatedData = dataCountry.map((obj) => {
          if (obj.id == event.target.value) {
            return { ...obj, ...{ sanction: "0" } };
          }
          return obj;
        });
        setDataCountry(updatedData);
      }
    }
  };

  const updateCountriesStatus = (e) => {
    if (dataCountry.length > 0) {
      axios.post("api/countries/bulk", dataCountry).then((res) => {
        if (res.status === 200) {
          swal("Успех", "Список стран успешно обновлен", "success");
        } else {
          swal("Ошибка", "При создание клиента произошла ошибка", "error");
        }
      });
    }
  };

  return (
    <section
      className={`filter-countries d-flex justify-content-start align-items-start section-border`}
    >
      <table className="table table-striped table-hover table-history">
        <tbody id="allClientsInfo">
          <tr>
            <th> Список стран</th>
            <th> №1 (Рискованные)</th>
            <th> №2 (Высокорискованные )</th>
          </tr>
          {optionsCountry.map((item, index) => {
            return (
              <tr className="client-data" key={index}>
                <td data-status={item.sanction} data-id={item.id}>
                  {item.name}
                </td>
                <td className="checked-one text-center">
                  <Form.Check
                    inline
                    name={`operation-checkbox-one`}
                    type={"checkbox"}
                    id={`${index}`}
                    value={item.id}
                    defaultChecked={item.sanction === 1 ? true : false}
                    data-type={1}
                    className={"countries_check"}
                    onChange={handleCheck}
                  />
                </td>
                <td className="checked-two text-center">
                  <Form.Check
                    inline
                    name={`operation-checkbox-two`}
                    type={"checkbox"}
                    id={`${index}_two`}
                    value={item.id}
                    data-type={2}
                    defaultChecked={item.sanction === 2 ? true : false}
                    className={"countries_check"}
                    onChange={handleCheck2}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={`${countryStyles.button_block}`}>
        <button onClick={updateCountriesStatus}>Обновить статус стран</button>
      </div>
    </section>
  );
}
export default CountriesFilter;
