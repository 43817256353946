import UploadBlForm from "./upadate-bl-form/upload-bl-form";
import { useEffect, useState } from "react";
import deleteBtn from "../../../images/delete.svg";
import axios from "axios";
import moment from "moment";

function UpdateBackList({ titleChange }) {
  // Title страницы передаем в шапку
  useEffect(() => {
    titleChange("Обновить ЧС");
  }, [titleChange]);
  //

  const [blackListData, setBlackListData] = useState([]);
  const [updatedDate, setDate] = useState();
  const [fileName, setFileName] = useState();
  const [blName, setBlname] = useState();
  const [status, setStatus] = useState();
  const [blListData, setBlList] = useState();

  useEffect(() => {
    axios.get("/import").then((res) => {
      console.log(res);
      setBlList(res.data);
    });
  }, [updatedDate]);

  const getBlData = (value) => {
    setBlackListData(value);
  };
  const getUpdateDate = (value) => {
    setDate(value);
  };
  const getBlFile = (value) => {
    setFileName(value);
  };
  const getBlackListName = (value) => {
    setBlname(value);
  };
  const getStatus = (value) => {
    setStatus(value);
  };
  return (
    <div className="test section-border">
      <UploadBlForm
        blData={getBlData}
        updateDate={getUpdateDate}
        getFile={getBlFile}
        getBlName={getBlackListName}
        getBlStatus={getStatus}
      />

      <section className="create-new-user-section">
        <div className="row gap-1">
          <div className=" col-xl-12 col-md-12 col-lg-12 ">
            <p>Результат обновления</p>
            <table className="table table-striped table-hover table-history w-100">
              <tbody id="allClientsInfo">
                <tr>
                  <th> Дата</th>
                  <th> Файл</th>
                  <th> Название ЧС</th>
                  <th> Статус</th>
                </tr>

                {blListData &&
                  blListData.logs.map((i, index) => {
                    return (
                      <tr className="client-data" key={index}>
                        <td>
                          {moment(i.date, "DD.MM.YYYY").format(
                            "DD.MM.YYYY HH:MM"
                          )}
                        </td>
                        <td>{i.file}</td>
                        <td>{i.black_list_name}</td>
                        <td>{i.status}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  );
}

export default UpdateBackList;
