import React from "react";
import modalStyles from "./user-cart-modal.module.css";
import Form from "react-bootstrap/Form";
import "photoswipe/dist/photoswipe.css";
import pdf_icon from "../../../images/pdf_icon.svg"

import { Gallery, Item } from "react-photoswipe-gallery";
import DatePicker from "react-flatpickr";
import moment from "moment";
import { useState } from "react";

function Verification({ editOn, setEdit, editInfo, userdata }) {
    return (
        <>
            {/* Верификация  */}
            <li className={`${modalStyles.user_info_item}`}>
                <b>Верифицирован : </b>
                {editOn ? (
                    <Form.Select
                        name="verification"
                        onChange={(e) => {
                            setEdit({
                                ...editInfo,
                                verification: parseInt(e.target.value),
                            });
                        }}
                        className="w-50"
                        defaultValue={userdata.verification}
                    >
                        <option selected={userdata.verification === 1} value={1}>
                            Да
                        </option>
                        <option selected={userdata.verification === 0} value={0}>
                            Нет
                        </option>
                    </Form.Select>
                ) : (
                    <span
                        className={`${modalStyles.user__editinput}  ${
                            userdata.verification === 1 ? "text-green" : "text-danger"
                        }`}
                    >
            {userdata.verification === 1 ? "Да" : "Нет"}
          </span>
                )}
            </li>
            {/*  */}
            {/* Дата первичной верификации */}
            <li className={`${modalStyles.user_info_item}`}>
                <b>Дата первичной верификации : </b>
                {editOn ? (
                    <DatePicker
                        className=" w-50  bg-white form-control"
                        defaultValue={moment(
                            userdata.verification_date,
                            "DD/MM/YYYY"
                        ).format("YYYY-MM-DD")}
                        onChange={(date) => {
                            const selectedDate = new Date(date).toLocaleDateString();
                            setEdit({
                                ...editInfo,
                                verification_date: moment(selectedDate, "DD-MM-YYYY").format(
                                    "DD/MM/YYYY"
                                ),
                            });
                        }}
                    />
                ) : (
                    <span className={modalStyles.user__editinput}>
            {userdata.verification_date}
          </span>
                )}
            </li>
            {/*  */}
            {/* Дата верификации */}
            <li className={`${modalStyles.user_info_item}`}>
                <b>Дата верификации : </b>
                {editOn ? (
                    <DatePicker
                        className="mb-3  w-50  bg-white form-control"
                        defaultValue={moment(
                            userdata.verification_date,
                            "DD/MM/YYYY"
                        ).format("YYYY-MM-DD")}
                        onChange={(date) => {
                            const selectedDate = new Date(date).toLocaleDateString();
                            setEdit({
                                ...editInfo,
                                verification_date: moment(selectedDate, "DD-MM-YYYY").format(
                                    "DD/MM/YYYY"
                                ),
                            });
                        }}
                    />
                ) : (
                    <span className={modalStyles.user__editinput}>
            {userdata.verification_date}
          </span>
                )}
            </li>
            {/*  */}

            <div className="row">
                <Gallery>
                    {userdata.attachments.map((attachment, index) => {
                        if (attachment.type === "cv_photo") {
                            return (
                                <Item
                                    original={attachment.url}
                                    thumbnail={attachment.thumbnail_url}
                                    width="1024"
                                    height="768"
                                >
                                    {({ ref, open }) => (
                                        <div className="col-lg-4 text-center" key={index}>
                                            <div className="user__cart--block">
                                                <a href={`https://${attachment.url}`} target={'_blank'}>
                                                <img
                                                    className={"user_cart_img"}
                                                    src={`${pdf_icon}`}
                                                />


                                                <div className="page-file__option">
                                                    <button
                                                        type="button"
                                                        className="page-file__zoom"
                                                        onClick={open}
                                                    ></button>
                                                    <button
                                                        type="button"
                                                        className="page-file__delete"
                                                    ></button>
                                                </div>
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                </Item>
                            );
                        }
                    })}
                </Gallery>
            </div>
        </>
    );
}

export default Verification;
